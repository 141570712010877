import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DialogComponent } from 'src/app/library/components/dialog/dialog.component';
import { DialogInputComponent } from 'src/app/library/components/dialog-input/dialog-input.component';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-role-show',
    templateUrl: './role-show.component.html',
    styleUrls: ['./role-show.component.css']
})
export class RoleShowComponent implements OnChanges {

    @Input() roles;
    @Input() types;
    @Input() actions;
    @Input() resolvers;
    @Input() transformers;
    public roleOrActionList = [];
    public typeList = [];
    public resolverList = [];

    constructor(
        public dialog: MatDialog
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.types) {
            this.typeList = [];

            changes.types.currentValue.split(',').forEach((type) => {
                this.typeList.push({ key: type, value: type });
            });
        }
    }

    public add(): void {
        const dialogRef = this.dialogAdd(this.roles, 'Ajouter un roles');

        dialogRef.afterClosed()
        .subscribe((res) => {
            if (res) {
                const names = [];
                this.roles.forEach((role) => {
                    names.push(Object.keys(role)[0]);
                });
                if (!names.includes(res)) {
                    this.roles.push({
                        [res]: {
                            actions: [],
                            options: [],
                        }
                    });
                }
            }
        });

    }

    public delete(roleIndex: number): void {
        const dialogRef = this.dialogDelete(this.roles[roleIndex], 'Êtes-vous sûr de vouloir supprimer ce rôle ?');

        dialogRef.afterClosed()
        .subscribe((res) => {
            if (res) {
                this.roles.splice(roleIndex, 1);
            }
        });
    }

    public xeditablChange(field: 'key', textChanged: string, roleIndex: number): void {
        if (field === 'key') {
            const names = [];
            this.roles.forEach((role) => {
                names.push(Object.keys(role)[0]);
            });
            if (!names.includes(textChanged)) {
                this.roles[roleIndex] = {
                    [textChanged]: Object.values(this.roles[roleIndex])[0]
                };
            }
        }
    }

    private dialogDelete(dialogElement: any, dialogMessage: string): MatDialogRef<DialogComponent, any> {
        return this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
                title: 'Supprimer',
                message: dialogMessage,
                element:  dialogElement,
                textButton: 'Supprimer'
            },
        });
    }

    private dialogAdd(dialogElement: any, dialogMessage: string): MatDialogRef<DialogInputComponent, any> {
        return this.dialog.open(DialogInputComponent, {
            width: '500px',
            data: {
                title: 'Ajouter',
                message: dialogMessage,
                element:  dialogElement,
                textButton: 'Ajouter'
            },
        });
    }
}
