import { NgModule } from '@angular/core';
import { LibraryModule } from '../library/library.module';
import { SharedModule } from '../shared/shared.module';
import { ProjectTableComponent } from './project/table/project-table.component';
import { ProjectShowComponent } from './project/show/project-show.component';
import { ProjectRouteTableComponent } from '../components/table/project-route-table/project-route-table.component';
import { ProjectRouteShowComponent } from './project/show-route/project-route-show.component';
import { ProjectApiService } from '../services/project-api.service';
import { ProjectRouteApiService } from '../services/project-route-api.service';
import { DialogComponent } from '../library/components/dialog/dialog.component';
import { DialogInputComponent } from '../library/components/dialog-input/dialog-input.component';
import { HomeComponent } from './home/home.component';
import { ConnectionComponent } from './connection/connection.component';
import { PermissionModule } from '../components/permission/permission.module';
import { QueryModule } from '../components/query/query.module';
import { DialogModule } from '../components/dialog/dialog.module';
import { DialogProjectFormComponent } from '../components/dialog/dialog-project-form/dialog-project-form.component';
import { DialogRouteFormComponent } from '../components/dialog/dialog-route-form/dialog-route-form.component';

@NgModule({
    declarations: [
        ProjectTableComponent,
        ProjectShowComponent,
        ProjectRouteTableComponent,
        ProjectRouteShowComponent,
        HomeComponent,
        ConnectionComponent
    ],
    imports: [
        LibraryModule,
        SharedModule,
        PermissionModule,
        QueryModule,
        DialogModule
    ],
    providers: [
        ProjectApiService,
        ProjectRouteApiService
    ],
    exports: [
        ConnectionComponent
    ],
    entryComponents: [
        DialogComponent,
        DialogInputComponent,
        DialogProjectFormComponent,
        DialogRouteFormComponent,
    ]
})
export class PageModule { }
