import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { LibraryModule } from 'src/app/library/library.module';
import { DialogComponent } from 'src/app/library/components/dialog/dialog.component';
import { DialogInputComponent } from 'src/app/library/components/dialog-input/dialog-input.component';
import { ColumnQueryComponent } from './column-query/column-query.component';
import { ParameterQueryComponent } from './parameter-query/parameter-query.component';
import { ConditionQueryComponent } from './condition-query/condition-query.component';
import { FilterQueryComponent } from './filter-query/filter-query.component';

@NgModule({
    declarations: [
        ColumnQueryComponent,
        ParameterQueryComponent,
        ConditionQueryComponent,
        FilterQueryComponent
    ],
    imports: [
        LibraryModule,
        SharedModule
    ],
    providers: [],
    exports: [
        ColumnQueryComponent,
        ParameterQueryComponent,
        ConditionQueryComponent,
        FilterQueryComponent
    ],
    entryComponents: [
        DialogComponent,
        DialogInputComponent
    ]
})
export class QueryModule {}
