import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { AwsService } from './aws.service';

@Injectable()
export class ConnectionService {

    public username: string;
    public password: string;
    public isConnected = false;
    public error: string;
    private token: any;
    private user: any;

    constructor(public awsService: AwsService, private router: Router) {
        this.awsService.loadUserSession().subscribe((session: AWSCognito.CognitoUserSession) => {
            this.connect(session.getIdToken().getJwtToken());
        });
    }

    public login(username: string, password: string) {
        this.username = username;
        this.password = password;

        this.awsService.authenticateUserPool(username, password, (message: string, result: any) => {
            if (message != null) { // error
                this.error = message;
            } else { // success
                this.connect(result.getIdToken().getJwtToken());
            }
        });
    }

    public logout() {
        this.isConnected = false;
        this.token = null;
        this.user = null;

        this.router.navigateByUrl('/').then(e => {
            if (e) {
                console.log('Navigation is successful!');
            } else {
                console.log('Navigation has failed!');
            }
        });
    }

    private userDataFromUserPools() {
        this.awsService.getInfoApiUserPools(this.token)
            .subscribe(
                user => {
                    this.user = user;
                    console.log('GET with JWT to API Gateway');
                },
                error => this.error = error
            );
    }

    private connect(token) {
        this.token = token;
        this.isConnected = true;
        this.userDataFromUserPools();
    }

    public getToken() {
        if (!this.token) {
            this.logout();
            throw Error('No token available');
        }
        return this.token;
    }
}
