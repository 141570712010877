import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ConnectionService } from '../../shared/services/connection.service';

@Injectable()
export class EditableService extends ApiService {

    constructor(protected http: HttpClient, protected connectionService: ConnectionService) {
        super(http, connectionService);
    }

    public editable(url, data): Observable<any> {
        const options = this.getOptions();

        return this.http.post(this.apiUrl + url, data, options) as Observable<any>;
    }
}
