import { Injectable } from '@angular/core';

@Injectable()
export class JsonProjectVerfication {

    public execute(jsonData: any): any {

        const connection = ['database', 'host', 'password', 'type', 'user'];
        let correct = true;

        connection.forEach((data) => {
            correct = this.stringNull(jsonData.connection[data]);
        });

        if (jsonData.permission && correct) {
            correct = this.permissionVerfication(jsonData.permission);
            if (correct) {
                correct = this.stringNull(jsonData.user);
            }
        }

        return correct;
    }

    private permissionVerfication(json: any) {
        let correct = true;
        const queryPermission = ['idColumn', 'roleColumn', 'table', 'userColumn'];
        const queryRole = ['idColumn', 'label', 'nameColumn', 'table'];

        correct = this.verifName(json);
        if (correct) {
            queryPermission.forEach((data) => {
                correct = this.stringNull(json.query.permission[data]);
            });
        }
        if (correct) {
            queryRole.forEach((data) => {
                correct = this.stringNull(json.query.role[data]);
            });
        }
        return correct;
    }

    private verifName(json) {
        let correct = true;
        switch (typeof(json.name)) {
            case 'string':
                correct = this.stringNull(json.name);
                break;
            case 'object':
                json.name.forEach((name) => {
                    correct = this.stringNull(name);
                });
                if (json.name.lenght < 1) {
                    correct = false;
                }
                break;
            default:
                break;
        }
        // if (typeof(json.name) === 'string' ) {
        //     correct = this.stringNull(json.name);
        // }
        return correct;
    }

    private stringNull(info) {
        let correct = true;
        if (info === 'null' ||
            info === undefined ||
            info.trim() === '') {
            correct = false;
        }
        return correct;
    }

}
