import { OnInit, Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { tap } from 'rxjs/operators';
import { DialogComponent } from 'src/app/library/components/dialog/dialog.component';

@Component({
    selector: 'app-column-query',
    templateUrl: './column-query.component.html',
    styleUrls: ['./column-query.component.css']
})
export class ColumnQueryComponent {

    @Input() query;
    @Input() routeParameters;

    constructor(private dialog: MatDialog) {}

    public add(): void {
        this.query.columns.push({
            COLUMN: 'PARAMETER'
        });
    }

    public xeditableChange(type: 'key'|'value', textChanged: string, columnIndex: number) {
        if (type === 'key') {
            this.query.columns[columnIndex] = {
                [textChanged]: Object.values(this.query.columns[columnIndex])[0]
            };
        }
        if (type === 'value') {
            this.query.columns[columnIndex] = {
                [Object.keys(this.query.columns[columnIndex])[0]]: textChanged
            };
        }
    }

    public delete(columnIndex): void {
        const dialogRef = this.dialogDelete(this.query.columns[columnIndex], 'Êtes-vous sûr de supprimer cette colonne ?');

        dialogRef.afterClosed().pipe(
            tap((res) => {
                if (res) {
                    this.query.columns.splice(columnIndex, 1);
                }
            })
        ).subscribe();
    }

    private dialogDelete(dialogElement: any, dialogMessage: string): MatDialogRef<DialogComponent, any> {
        return this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
                title: 'Supprimer',
                message: dialogMessage,
                element:  dialogElement,
                textButton: 'Supprimer'
            },
        });
    }
}
