import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'app-query-show',
    templateUrl: './query-show-component.html',
    styles: ['./query-show.component.css']
})
export class QueryShowComponent {

    @Input() querys;

    constructor(
        public dialog: MatDialog
    ) {}

    public xeditablChange(type: 'permission' | 'role', field: string, textChanged: string): void {

        this.querys[type][field] = textChanged;
    }
}
