import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConnectionService } from 'src/app/shared/services/connection.service';

export interface HttpHeaderObject {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?;
    withCredentials?: boolean;
}

export class ApiService {

    protected apiUrl = 'https://37mlw0g6v6.execute-api.eu-west-3.amazonaws.com/preprod';

    constructor(protected http: HttpClient, protected connectionService: ConnectionService) { }

    protected getOptions(params = null, headerData = {}): HttpHeaderObject {
        const headerConfig = {
            Authorization: this.connectionService.getToken(),
            'Content-Type': 'application/json',
        };

        Object.keys(headerData).forEach(key => headerConfig[key] = headerData[key]);

        const header: HttpHeaderObject = {
            headers: new HttpHeaders(headerConfig)
        };

        if (params) {
            header.params = params;
        }

        return header;
    }

    public getTableData(sortColumn, sortOrder = 'asc', pageNumber = 0, pageSize = 5, filter = '', extra = {}): Observable<any[]> {
        throw Error('Method getTableData not declare');
    }

    public edit(id, data): Observable<any> {
        throw Error('Method edit not declare');
    }

    public add(data): Observable<any> {
        throw Error('Method add not declare');
    }
}
