import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectRouteApiService } from 'src/app/services/project-route-api.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DialogRouteFormComponent } from '../../dialog/dialog-route-form/dialog-route-form.component';
import { FormControl } from '@angular/forms';
import { startWith, map, tap, switchMap, filter, concatMap, toArray } from 'rxjs/operators';
import { Observable, ReplaySubject, of, Subject, from } from 'rxjs';
import { ProjectApiService } from 'src/app/services/project-api.service';

@Component({
    selector: 'app-project-route-table',
    templateUrl: './project-route-table.component.html',
    styleUrls: ['./project-route-table.component.css']
})
export class ProjectRouteTableComponent implements OnInit {


    @Input() extra: any;

    // public projectRoutes: any[] = [];
    public projectRoutes2: any[] = [];
    public projectCode;
    public projectCodeSelect = [];
    public projectCodeForm = new FormControl();

    tableSearchControl = new FormControl();
    filteredOptions: Observable<string[]>;

    constructor(
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        public projectApiService: ProjectApiService,
        public projectRouteApiService: ProjectRouteApiService) {
        const id = this.route.snapshot.paramMap.get('id');
        this.projectApiService.getProjectData(id)
            .subscribe((project: any) => {
                if (typeof project.name === 'string') {
                    this.projectCodeSelect.push(project.name);
                } else {
                    project.name.forEach(projectTable => {
                        this.projectCodeSelect.push(projectTable.S);
                    });
                }
                this.projectCode = this.projectCodeSelect[0];
                this.projectCodeForm.setValue(this.projectCodeSelect);
                this.initRoute();
            });
    }

    private _filter(value: string): string[] {
        if (this.projectRoutes2[0] !== undefined && this.projectRoutes2[0] !== null) {
            const filterValue = value.toLowerCase();
            const filterResult = [];
            this.projectRoutes2.forEach(option => {
                const key = Object.keys(option)[0];
                const valueByKey = option[key].filter(opt => opt.toLowerCase().includes(filterValue));
                filterResult.push({ [key]: valueByKey });
            });

            return filterResult;
        }

        return this.projectRoutes2;
    }
    public ngOnInit(): void {

        this.initRoute();
        // this.projectRouteApiService.getProjectRoutes(this.projectCode)
        //     .subscribe(
        //         (res: any) => this.projectRoutes = res
        //     );
    }

    private initRoute() {
        from(this.projectCodeSelect).pipe(
            concatMap(
                (codeSelect) => this.projectRouteApiService.getProjectRoutes(codeSelect),
                (codeSelect, res) => {
                    this.projectRoutes2.push({ [codeSelect]: res });
                }
            ),
            toArray()
        ).subscribe(
            () => {
                this.filteredOptions = this.tableSearchControl.valueChanges
                    .pipe(
                        startWith(''),
                        map(value => this._filter(value)),
                    );
            },
        );
    }

    public renderShowPage(element, key): string {
        this.projectCode = key;
        const formatKey = element.replace(/([\/])/g, '&');

        return `/project/${this.projectCode}/route/show/${formatKey}`;
    }

    public add(): void {
        const dialogRef = this.dialog.open(DialogRouteFormComponent, {
            width: '700px',
            data: { info: this.projectCodeSelect }
        });

        dialogRef.afterClosed()
            .pipe(
                filter(routeSubmited => routeSubmited),
                concatMap(
                    (routeSubmited: { key: string, code?: string }) =>
                        this.projectRouteApiService.postRoute(routeSubmited, routeSubmited.code),
                    (routeSubmited: { key: string, code?: string }, res) => {
                        this.snackBar.open('Route créée avec succès', null, { duration: 2000 });
                        // this.projectRoutes.push(routeSubmited.key);
                        this.projectRoutes2 = [];
                    }
                ),
            )
            .subscribe(
                () => {
                    this.initRoute();
                },
                () => this.snackBar.open('Une erreur s\'est produite, veuillez réessayer ultérieurement', null, { duration: 2000 })
            );
    }

    public duplic(id, code) {

        const dialogRef = this.dialog.open(DialogRouteFormComponent, {
            width: '700px',
            data: { info: this.projectCodeSelect }
        });

        this.projectRouteApiService.getProjectRouteData(code, id)
        .pipe(
            concatMap(
                (projectRoute: any) => dialogRef.afterClosed(),
                (projectRoute: any, routeSubmited) => {
                    return {project: projectRoute, route: routeSubmited};
                }
            ),
            filter(info => info.route),
            concatMap(
                (data: {project: any, route: { key: string, code?: string }} ) =>
                    this.projectRouteApiService.postRoute(data.route, data.route.code),
                (data: {project: any, route: { key: string, code?: string }}, res) => {
                    this.snackBar.open('Route créée avec succès', null, { duration: 2000 });
                    data.project.queries.forEach((dataQueries) => {
                        if (dataQueries.jsonColumns) {
                            dataQueries.jsonColumns = dataQueries.jsonColumns.toString();
                        }
                    });
                    data.project.key = data.route.key;
                    return data;
                }
            ),
            concatMap(
                (data) => this.projectRouteApiService.putProjectRouteData(data.route.code, data.route.key, data.project),
                (data, res) => {
                    this.snackBar.open('Route modifié avec succès', null, { duration: 2000 });
                    this.projectRoutes2 = [];
                }
            )
        )
        .subscribe(
            () => this.initRoute(),
            () => {
                this.snackBar.open('Une erreur s\'est produite, veuillez réessayer ultérieurement', null, { duration: 2000 });
            }
        );
    }
}
