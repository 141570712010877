import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TableComponent } from './components/table/table.component';
import { EditableService } from './services/editable.service';
import { EditableComponent } from './components/editable/editable.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogInputComponent } from './components/dialog-input/dialog-input.component';


@NgModule({
    declarations: [
        TableComponent,
        EditableComponent,
        DialogComponent,
        DialogInputComponent
    ],
    imports: [
        SharedModule
    ],
    providers: [
        EditableService
    ],
    exports: [
        TableComponent,
        EditableComponent,
        DialogComponent,
        DialogInputComponent
    ]
})
export class LibraryModule { }
