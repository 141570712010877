import { Injectable } from '@angular/core';
import { DynamoProjectRoute, DynamoProjectRouteQuery } from '../models/dynamo-project-route.model';
import {
    JsonProjectRoute,
    JsonProjectRouteQuery,
    JsonProjectRouteParametersType,
    JsonProjectRouteQueryColumn,
    JsonProjectRouteQueryFilter,
    JsonProjectRouteQueryParameter,
    JsonProjectRouteQueryCondition,
    JsonProjectRouteJson
} from '../models/json-project-route.model';

@Injectable()
export class DynamoToJsonProjectRouteTransform {

    public execute(dynamoData: /*DynamoProjectRoute*/any): /*JsonProjectRoute*/any {
        if (!dynamoData.Item) {
            throw Error('Cannot create project route');
        }
        let dynamoTransformToObject = false;

        if (dynamoData.Item.result.M.transformToObject) {
            dynamoTransformToObject = dynamoData.Item.result.M.transformToObject.BOOL;
        }
        const info = {
            json: this.jsonTransform(dynamoData),
            key: dynamoData.Item.key.S,
            parametersType: this.parametersTypeTransform(dynamoData),
            queries: this.queriesTransform(dynamoData),
            result: {
                expression: dynamoData.Item.result ? dynamoData.Item.result.M.expression.S : 'null',
                transformToObject: dynamoTransformToObject
            },
            staticParameters: this.staticParametersTransform(dynamoData) // {}
        };

        if (dynamoData.Item.result && dynamoData.Item.result.M && dynamoData.Item.result.M.lambda) {
            info.result = Object.assign(info.result, {
                lambda: this.resultLambdaTransform(dynamoData.Item.result.M.lambda)
            });
            this.resultLambdaTransform(dynamoData.Item.result.M.lambda);
        }

        if (dynamoData.Item.result && dynamoData.Item.result.M && dynamoData.Item.result.M.indexes) {
            info.result = Object.assign(info.result, {
                indexes: this.resultIndexeTransform(dynamoData.Item.result.M.indexes)
            });
            this.resultLambdaTransform(dynamoData.Item.result.M.lambda);
        }
        return info;
    }

    private jsonTransform(dynamoData: any/*DynamoProjectRoute*/): /*JsonProjectRouteJson[]*/any[] {

        const formatJson = [];

        if (dynamoData.Item.json) {
            dynamoData.Item.json.L.forEach((item) => {
                const formatJsonParameters = [];
                Object.keys(item.M.parameters.M).forEach((parameterName: string) => {
                    formatJsonParameters.push({
                        [parameterName]: item.M.parameters.M[parameterName].S
                    });
                });
                let formatJsonQuery = {};
                if (item.M.query && item.M.query.M) {
                    Object.keys(item.M.query.M).forEach((parameterQuery: string) => {
                        formatJsonQuery = Object.assign(formatJsonQuery, {
                            [parameterQuery]: item.M.query.M[parameterQuery].S
                        });
                    });
                }

                formatJson.push({
                    keyName: item.M.keyName.S,
                    parameters: formatJsonParameters,
                    query: formatJsonQuery
                });
            });
        }

        return formatJson;
    }

    private queriesTransform(dynamoData: /*DynamoProjectRoute*/any): JsonProjectRouteQuery[] {
        const formatQueries = [];

        if (!dynamoData.Item.queries) {
            return formatQueries;
        }

        dynamoData.Item.queries.L.forEach((query) => {
            const queryJsonColumns = [];
            if (query.M.jsonColumns) {
                query.M.jsonColumns.L.forEach((jsonColumn) => {
                    queryJsonColumns.push(jsonColumn.S);
                });
            }

            const queryRequired = [];
            if (query.M.required) {
                query.M.required.L.forEach((requiredField) => {
                    queryRequired.push(requiredField.S);
                });
            }

            formatQueries.push({
                result: query.M.result ? query.M.result.S : 'null',
                type: query.M.type.S,
                query: query.M.query ? query.M.query.S : 'null',
                columns: this.queryColumnsTransform(query),
                filters: this.queryFiltersTransform(query),
                jsonColumns: queryJsonColumns.length > 0 ? queryJsonColumns /*.join(',')*/ : ['null'],
                parameters: this.queryParametersTransform(query),
                permissionAction: query.M.permissionAction ? query.M.permissionAction.S : 'null',
                required: queryRequired.length > 0 ? queryRequired.join(',') : 'null',
                data: query.M.data ? query.M.data.S : 'null',
                conditions: this.queryConditionsTransform(query),

                functionName: query.M.functionName ? query.M.functionName.S : 'null',
                region: query.M.region ? query.M.region.S : 'null'
            });
        });

        return formatQueries;
    }

    private parametersTypeTransform(dynamoData: DynamoProjectRoute): JsonProjectRouteParametersType[] {
        const formatParametersType = [];

        if (dynamoData.Item.parametersType) {
            Object.keys(dynamoData.Item.parametersType.M).forEach((paramter) => {
                formatParametersType.push({
                    [paramter]: dynamoData.Item.parametersType.M[paramter].S
                });
            });
        }

        return formatParametersType;
    }

    private staticParametersTransform(dynamoData: any): any[] {
        const formatStaticParameters = [];

        if (dynamoData.Item.staticParameters) {
            Object.keys(dynamoData.Item.staticParameters.M).forEach((paramter) => {
                formatStaticParameters.push({
                    [paramter]: dynamoData.Item.staticParameters.M[paramter].S
                });
            });
        }

        return formatStaticParameters;
    }

    private resultLambdaTransform(dynamoData: any): any {
        let lambdaRegion;
        if (dynamoData.M.region) {
            lambdaRegion = dynamoData.M.region.S;
        } else {
            lambdaRegion = 'null';
        }
        const resultLambda = {
            data: [],
            functionName: dynamoData.M.functionName.S,
            region: lambdaRegion// dynamoData.M.region.S
        };
        if (Object.keys(dynamoData.M.data.M).length !== 0) {
            Object.keys(dynamoData.M.data.M).forEach((data) => {
                resultLambda.data.push({
                    [data]: dynamoData.M.data.M[data].S
                });
            });
        }

        return resultLambda;
    }

    private resultIndexeTransform(dynamoData: any): any {
        const resultIndexe = [];
        Object.keys(dynamoData.M).forEach((data) => {
            const val = [];
            dynamoData.M[data].L.forEach((data1) => {
                val.push(data1.S);
            });
            resultIndexe.push({
                [data]: val
            });
        });

        return resultIndexe;
    }

    private queryColumnsTransform(query: DynamoProjectRouteQuery): JsonProjectRouteQueryColumn[] {
        const queryColumns = [];

        if (query.M.columns) {
            Object.keys(query.M.columns.M).forEach((column) => {
                queryColumns.push({
                    [column]: query.M.columns.M[column].S
                });
            });
        }

        return queryColumns;
    }

    private queryFiltersTransform(query: DynamoProjectRouteQuery): JsonProjectRouteQueryFilter[] {
        const queryFilters = [];
        if (query.M.filters) {
            Object.keys(query.M.filters.M).forEach((filterKey) => {
                queryFilters.push({
                    [filterKey]: {
                        alias: query.M.filters.M[filterKey].M.alias.S,
                        condition: query.M.filters.M[filterKey].M.condition.S,
                    }
                });
            });
        }

        return queryFilters;
    }

    private queryParametersTransform(query: DynamoProjectRouteQuery): JsonProjectRouteQueryParameter[] {
        const queryParameters = [];

        if (query.M.parameters) {
            Object.keys(query.M.parameters.M).forEach((parameter) => {
                queryParameters.push({
                    [parameter]: query.M.parameters.M[parameter].S
                });
            });
        }

        return queryParameters;
    }

    private queryConditionsTransform(query: DynamoProjectRouteQuery): JsonProjectRouteQueryCondition[] {
        const queryConditions = [];

        if (query.M.conditions) {
            query.M.conditions.L.forEach((condition: any) => {
                queryConditions.push({
                    parameter: condition.M.parameter ? condition.M.parameter.S : 'null',
                    type: condition.M.type.S,
                    value: condition.M.value ? condition.M.value.S : 'null'
                });
            });
        }

        return queryConditions;
    }
}
