import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'roleFilter'
})
export class RoleFilterPipe implements PipeTransform {
    transform(roleOrActions, roleToExclude: string) {
        if (!roleOrActions || !roleToExclude) {
            return roleOrActions;
        }

        return roleOrActions.filter((roleOrAction) => roleOrAction.key !== roleToExclude);
    }

}
