import { Injectable } from '@angular/core';
import { DynamoProject, DynamoProjectPermission, DynamoProjectPermissionRole } from '../models/dynamo-project.model';
import {
    JsonProject,
    JsonProjectPermission,
    JsonProjectPermissionResolver,
    JsonProjectPermissionRole,
    JsonProjectPermissionRoleAction,
    JsonProjectPermissionTransformer,
    JsonProjectPermissionRoleOptions,
} from '../models/json-project.model';

@Injectable()
export class DynamoToJsonProjectTransform {

    public execute(dynamoData: any): JsonProject {
        const jsonData: JsonProject = {
            key: dynamoData.Item.key.S,
            connection: {
                database: dynamoData.Item.connection.M.database.S,
                host: dynamoData.Item.connection.M.host.S,
                password: dynamoData.Item.connection.M.password.S,
                type: dynamoData.Item.connection.M.type.S,
                user: dynamoData.Item.connection.M.user.S
            },
            name: dynamoData.Item.name.S || dynamoData.Item.name.L,
            user: dynamoData.Item.user ? dynamoData.Item.user.S : 'null',
            id: '1',
            stage: 'DEV',
        };

        if (dynamoData.Item.permission) {
            jsonData.permission = this.permissionTransform(dynamoData);
        }
        return jsonData;
    }

    private permissionTransform(dynamoData: /*DynamoProject*/any): /*JsonProjectPermission*/any {
        let projectPermission = {
            actions: 'null',
            query: {},
            resolvers: [],
            roles: [],
            transformer: [],
            types: 'null'
        };

        if (!dynamoData.Item.permission) {
            return projectPermission;
        }
        const dynamoPermission = dynamoData.Item.permission.M.core.M;
        let dynamoPermissionModules; // = dynamoData.Item.permission.M.modules.M;
        if (dynamoData.Item.permission.M.module) {
            dynamoPermissionModules = dynamoData.Item.permission.M.module.M;
        } else if (dynamoData.Item.permission.M.modules) {
            dynamoPermissionModules = dynamoData.Item.permission.M.modules.M;
        }

        if (dynamoPermission) {
            const projectPermissionActions = [];
            if (dynamoPermission.actions) {
                dynamoPermission.actions.L.forEach((action) => {
                    projectPermissionActions.push(action.S);
                });
            }

            const projectPermissionTypes = [];
            if (dynamoPermission.types) {
                dynamoPermission.types.L.forEach((type) => {
                    projectPermissionTypes.push(type.S);
                });
            }
            projectPermission = {
                actions: projectPermissionActions.length > 0 ? projectPermissionActions.join(',') : 'action',
                query: this.permissionQueryTransform(dynamoData.Item.permission.M), // dynamoPermission.query.S,
                resolvers: this.permissionResolversTransform(dynamoPermission),
                roles: this.permissionRolesTransform(dynamoPermission),
                transformer: this.permissionTransformerTransform(dynamoPermission),
                types: projectPermissionTypes.length > 0 ? projectPermissionTypes.join(',') : 'type'
            };
        }

        if (dynamoPermissionModules) {
            const modulesPermission = [];

            Object.keys(dynamoPermissionModules).forEach((modules) => {
                const projectPermissionActions = [];
                if (dynamoPermissionModules[modules].M.actions) {
                    dynamoPermissionModules[modules].M.actions.L.forEach((action) => {
                        projectPermissionActions.push(action.S);
                    });
                }

                const projectPermissionTypes = [];
                if (dynamoPermissionModules[modules].M.types) {
                    dynamoPermissionModules[modules].M.types.L.forEach((type) => {
                        projectPermissionTypes.push(type.S);
                    });
                }
                const projectModulePermission = {
                    actions: projectPermissionActions.length > 0 ? projectPermissionActions.join(',') : 'action',
                    query: this.permissionQueryTransform(dynamoData.Item.permission.M), // dynamoPermission.query.S,
                    resolvers: this.permissionResolversTransform(dynamoPermissionModules[modules].M),
                    roles: this.permissionRolesTransform(dynamoPermissionModules[modules].M),
                    transformer: this.permissionTransformerTransform(dynamoPermissionModules[modules].M),
                    types: projectPermissionTypes.length > 0 ? projectPermissionTypes.join(',') : 'type'
                };
                modulesPermission.push({[modules]: projectModulePermission});
            });
            projectPermission = Object.assign(projectPermission, {modules: modulesPermission});
        }

        return projectPermission;
    }

    private permissionQueryTransform(permission: any) {
        const projectPermissionQuery = {
            permission: {
                idColumn: permission.query.M.permission.M.idColumn.S || 'null',
                roleColumn: permission.query.M.permission.M.roleColumn.S || 'null',
                table: permission.query.M.permission.M.table.S || 'null',
                userColumn: permission.query.M.permission.M.userColumn.S || 'null'
            },
            role: {
                idColumn: permission.query.M.role.M.idColumn.S || 'null',
                label: permission.query.M.role.M.label.S || 'null',
                nameColumn: permission.query.M.role.M.nameColumn.S || 'null',
                permissionAction: permission.query.M.role.M.permissionAction.S || 'null',
                table: permission.query.M.role.M.table.S || 'null'
            }
        };

        return projectPermissionQuery;
    }

    private permissionResolversTransform(permission: DynamoProjectPermission): JsonProjectPermissionResolver[] {
        const projectPermissionResolvers = [];

        if (permission.resolvers) {
            Object.keys(permission.resolvers.M).forEach((resolverName) => {
                const types = [];
                permission.resolvers.M[resolverName].M.types.L.forEach((type: { S: string; }) => types.push(type.S));
                const  perm = {
                    [resolverName]: {
                        query: permission.resolvers.M[resolverName].M.query ?
                            permission.resolvers.M[resolverName].M.query.S : '',
                        types: types.length > 0 ? types.join(',') : 'null'
                    }
                };
                projectPermissionResolvers.push(perm);
            });
        }

        return projectPermissionResolvers;
    }

    private permissionRolesTransform(permission: DynamoProjectPermission): JsonProjectPermissionRole[] {
        const projectPermissionRoles = [];

        if (permission.roles) {
            Object.keys(permission.roles.M).forEach((roleName) => {
                 projectPermissionRoles.push({
                    [roleName]: {
                        actions: this.permissionRoleActionsTransform(permission.roles.M[roleName]),
                        options: this.permissionRoleOptionsTransform(permission.roles.M[roleName])
                    }
                });
            });
        }

        return projectPermissionRoles;
    }

    private permissionTransformerTransform(permission: DynamoProjectPermission): JsonProjectPermissionTransformer[] {
        const projectPermissionTransformer = [];

        if (permission.transformer) {
            Object.keys(permission.transformer.M).forEach((perimeter: string) => {
                const types = permission.transformer.M[perimeter].M;
                const projectPermissionTransformerPerimeter = [];

                Object.keys(types).forEach((type: string) => {
                    const perimetersType = [];
                    let transformerPerimeter = {};

                    if (types[type].M.values) {
                        transformerPerimeter = {
                            values: types[type].M.values.S
                        };
                    } else {
                        types[type].M.types.L.forEach((perimeterType: { S: string; }) => perimetersType.push(perimeterType.S));

                        transformerPerimeter = {
                            types: perimetersType.length > 0 ? perimetersType.join(',') : 'null',
                            query: types[type].M.query.S
                        };
                    }
                    projectPermissionTransformerPerimeter.push({
                        [type]: transformerPerimeter/*{
                            query: types[type].M.query.S,
                            types: perimetersType.length > 0 ? perimetersType.join(',') : 'null',
                            values: types[type].M.values.S
                        }*/
                    });
                });

                projectPermissionTransformer.push({
                    [perimeter]: projectPermissionTransformerPerimeter
                });
            });
        }

        return projectPermissionTransformer;
    }

    private permissionRoleActionsTransform(role: DynamoProjectPermissionRole): JsonProjectPermissionRoleAction[] {
        const projectPermissionRoleActions = [];

        if (role.M.actions) {
            Object.keys(role.M.actions.M).forEach((actionOrRoleName) => {
                const projectPermissionRoleActionValues = [];
                role.M.actions.M[actionOrRoleName].L.forEach((roleValue) => {
                    projectPermissionRoleActionValues.push(roleValue.S);
                });

                projectPermissionRoleActions.push({
                    [actionOrRoleName]:
                    projectPermissionRoleActionValues.length > 0 ? projectPermissionRoleActionValues.join(',') : 'null'
                });
            });
        }

        return projectPermissionRoleActions;
    }

    private permissionRoleOptionsTransform(role: /*DynamoProjectPermissionRole*/any): any/*JsonProjectPermissionRoleOptions[]*/ {
        const projectPermissionRoleOptions = [];
        if (role.M.options) {
            Object.keys(role.M.options.M).forEach((type: string) => {
                if (role.M.options.M[type].M.optionPermissionTable) {
                    let permissionValue;
                    if (role.M.options.M[type].M.values) {
                        permissionValue = role.M.options.M[type].M.values.S;
                    } else {
                        permissionValue = 'null';
                    }
                    projectPermissionRoleOptions.push({
                        [type]: {
                            label: role.M.options.M[type].M.label.S || 'null',
                            optionPermissionTable: {
                                idColumn: role.M.options.M[type].M.optionPermissionTable.M.idColumn.S || 'null',
                                optionColumn: role.M.options.M[type].M.optionPermissionTable.M.optionColumn.S || 'null',
                                table: role.M.options.M[type].M.optionPermissionTable.M.table.S || 'null',
                            },
                            optionTable: {
                                idColumn: role.M.options.M[type].M.optionTable.M.idColumn.S || 'null',
                                nameColumn: role.M.options.M[type].M.optionTable.M.nameColumn.S || 'null',
                                permissionAction: role.M.options.M[type].M.optionTable.M.permissionAction.S || 'null',
                                table: role.M.options.M[type].M.optionTable.M.table.S || 'null',
                            },
                            values: permissionValue,
                        }
                    });
                } else if (role.M.options.M[type].M.query) {
                    projectPermissionRoleOptions.push({
                        [type]: {
                            query: role.M.options.M[type].M.query.S,
                            values: role.M.options.M[type].M.values.S || 'null'
                        }
                    });
                }

            });
        }

        return projectPermissionRoleOptions;
    }
}
