import { Injectable } from '@angular/core';
import { JsonProject, JsonProjectConnection, JsonProjectPermission, JsonProjectPermissionRoleData } from '../models/json-project.model';
import {
    DynamoProjectUpdate,
    DynamoProjectConnection,
    DynamoProjectPermission,
    DynamoProjectPermissionAction,
    DynamoProjectPermissionResolvers,
    DynamoProjectPermissionRoles,
    DynamoProjectPermissionRoleActions,
    DynamoProjectPermissionType,
    DynamoProjectPermissionRoleOptions,
    DynamoProjectPost
} from '../models/dynamo-project.model';

@Injectable()
export class JsonToDynamoProjectTransform {

    public executePostItem(jsonData: JsonProject): DynamoProjectPost {
        const dynamoData: any = {
            TableName: 'project',
            Item: {
                key: { S: jsonData.key },
                name: { S: jsonData.name },
                connection: { M: this.connectionTransform(jsonData.connection) }
            }
        };

        if (jsonData.user) {
            dynamoData.Item.user = { S: jsonData.user };
        }

        return dynamoData;
    }

    public executePostItem2(jsonData: JsonProject): DynamoProjectPost {
        const dynamoData: DynamoProjectPost = {
            TableName: 'project',
            Item: {
                key: { S: jsonData.key },
                name: { L: jsonData.name },
                connection: { M: this.connectionTransform(jsonData.connection) }
            }
        };

        if (jsonData.user) {
            dynamoData.Item.user = { S: jsonData.user };
        }

        return dynamoData;
    }

    public executePutItem(jsonData: JsonProject): DynamoProjectUpdate {
        const dynamoData: any = {
            TableName: '',
            Key: { key: { S: jsonData.key } },
            AttributeUpdates: {
                name: {
                    Action: 'PUT',
                    Value: { S: jsonData.name }
                },
                connection: {
                    Action: 'PUT',
                    Value: { M: this.connectionTransform(jsonData.connection) }
                },
                user: {
                    Action: 'PUT',
                    Value: { S: jsonData.user }
                }
            }
        };

        if (jsonData.permission) {
            dynamoData.AttributeUpdates.permission = {
                Action: 'PUT',
                Value: { M: this.permissionTransform(jsonData.permission) }
            };
        }

        return dynamoData;
    }

    public executePutItem2(jsonData: JsonProject): /*DynamoProjectUpdate*/any {
        const valueName = [];
        if (typeof(jsonData.name) === 'object') {
            jsonData.name.map((name) => { valueName.push({ S: name }); });
        } else {
            valueName.push({ S: jsonData.name });
        }
        const dynamoData: DynamoProjectUpdate = {
            TableName: '',
            Key: { key: { S: jsonData.key } },
            AttributeUpdates: {
                name: {
                    Action: 'PUT',
                    Value: { L: valueName } // { S: jsonData.name.toString() }
                },
                connection: {
                    Action: 'PUT',
                    Value: { M: this.connectionTransform(jsonData.connection) }
                },
                user: {
                    Action: 'PUT',
                    Value: { S: jsonData.user }
                }
            }
        };

        if (jsonData.permission) {
            dynamoData.AttributeUpdates.permission = {
                Action: 'PUT',
                Value: { M: this.permissionTransform(jsonData.permission) }
            };
        }

        return dynamoData;
    }

    private connectionTransform(connection: JsonProjectConnection): DynamoProjectConnection {
        return {
            host: { S: connection.host },
            database: { S: connection.database },
            user: { S: connection.user },
            password: { S: connection.password },
            type: { S: connection.type }
        };
    }

    private permissionTransform(permission: /*JsonProjectPermission*/any): /*DynamoProjectPermission*/any {
        let permissionTransform = {
            query: { M: this.permissionQueryTransform(permission) },
            core: {
                M : {
                    actions: { L: this.permissionActionsTransform(permission) },
                    resolvers: { M: this.permissionResolversTransform(permission) },
                    roles: { M: this.permissionRolesTransform(permission) },
                    types: { L: this.permissionTypesTransform(permission) },
                    transformer: { M: this.permissionTransformersTransform(permission)},
                }
            },
        };

        if (permission.modules) {
            let modules = {};
            permission.modules.forEach((modul) => {
                modules = Object.assign(modules, modul);
            });
            Object.keys(modules).forEach((modul) => {
                modules[modul] = {
                    M : {
                        actions: { L: this.permissionActionsTransform(modules[modul]) },
                        resolvers: { M: this.permissionResolversTransform(modules[modul]) },
                        roles: { M: this.permissionRolesTransform(modules[modul]) },
                        types: { L: this.permissionTypesTransform(modules[modul]) },
                        transformer: { M: this.permissionTransformersTransform(modules[modul])},
                    }
                };
            });
            const modules1 = { M: modules};
            permissionTransform = Object.assign(permissionTransform, {modules: modules1});
        }
        return permissionTransform;
        // return {
        //     query: { M: this.permissionQueryTransform(permission) },
        //     core: {
        //         M : {
        //             actions: { L: this.permissionActionsTransform(permission) },
        //             resolvers: { M: this.permissionResolversTransform(permission) },
        //             roles: { M: this.permissionRolesTransform(permission) },
        //             types: { L: this.permissionTypesTransform(permission) }
        //             // transformer: { this.permissionTransform()},
        //         }
        //     },
        // };
    }

    private permissionTransformersTransform(permission: any): any {
        const dynamoTransformer = {};

        if (permission.transformer) {
            permission.transformer.forEach((transformer) => {
                const transformerName = Object.keys(transformer)[0];

                dynamoTransformer[transformerName] = { M: {} };
                transformer[transformerName].forEach((data) => {
                    const transformerItem = Object.keys(data)[0];
                    const tab = [];
                    let transformerPerimeter = {};

                    if (data[transformerItem].types) {
                        (typeof data[transformerItem].types === 'string') ? data[transformerItem].types.split(',').forEach((item) => {
                                tab.push({S: item});
                            }) : data[transformerItem].types.forEach((item) => {
                                tab.push({S: item});
                            });
                        transformerPerimeter = {
                            query: { S: data[transformerItem].query },
                            types: { L: tab },
                        };
                    } else {
                        transformerPerimeter = {
                            values: { S: data[transformerItem].values }
                        };
                    }
                    dynamoTransformer[transformerName].M = Object.assign(dynamoTransformer[transformerName].M, {
                        [transformerItem]: {
                            M: transformerPerimeter/*{
                                query: { S: data[second].query },
                                types: { L: tab },
                                values: { S: data[second].values }
                            }*/
                        }
                    });
                });
            });
        }
        return dynamoTransformer;
    }

    private permissionQueryTransform(permission: any): any {
        return {
            permission: {
                M : {
                    roleColumn: {S: permission.query.permission.roleColumn},
                    table: {S: permission.query.permission.table},
                    userColumn: {S: permission.query.permission.userColumn},
                    idColumn: {S: permission.query.permission.idColumn},
                }
            },
            role: {
                M: {
                    permissionAction: {S: permission.query.role.permissionAction},
                    label: {S: permission.query.role.label},
                    nameColumn: {S: permission.query.role.nameColumn},
                    table: {S: permission.query.role.table},
                    idColumn: {S: permission.query.role.idColumn},
                }
            }
        };
    }

    private permissionActionsTransform(permission: JsonProjectPermission): DynamoProjectPermissionAction[] {
        const dynamoActions = [];

        if (permission.actions) {
            const actions = permission.actions.split(',');

            actions.forEach((action) => {
                dynamoActions.push({
                    S: action
                });
            });
        }

        return dynamoActions;
    }

    private permissionResolversTransform(permission: /*JsonProjectPermission*/any): /*DynamoProjectPermissionResolvers*/any {
        const dynamoResolvers = {};

        if (permission.resolvers) {

            permission.resolvers.forEach((resolver) => {
                Object.keys(resolver).forEach((name) => {
                    const tab = [];
                    if (typeof resolver[name].types === 'string') {
                        resolver[name].types = resolver[name].types.split(',');
                    }
                    resolver[name].types.forEach((data) => {
                        tab.push({S: data});
                    });
                    dynamoResolvers[name] = {
                        M: {
                            // query: { S: resolver[name].query },
                            types: { L:  tab}// resolver[name].type }
                        }
                    };
                    if (resolver[name].query !== undefined && resolver[name].query !== ''
                        && resolver[name].query.trim() !== '' && resolver[name].query !== null) {
                            dynamoResolvers[name].M = Object.assign(dynamoResolvers[name].M, {query: { S: resolver[name].query }});
                    }
                });
            });
        }

        return dynamoResolvers;
    }

    private permissionRolesTransform(permission: JsonProjectPermission): DynamoProjectPermissionRoles {
        const dynamoRoles = {};

        if (permission.roles) {
            permission.roles.forEach((role) => {
                Object.keys(role).forEach((roleName) => {
                    dynamoRoles[roleName] = {
                        M: {
                            actions: this.permissionRoleActionsTransform(role[roleName]),
                            options: this.permissionRoleOptionsTransform(role[roleName])
                        }
                    };
                });
            });
        }

        return dynamoRoles;
    }

    private permissionTypesTransform(permission: JsonProjectPermission): DynamoProjectPermissionType[] {
        const dynamoTypes = [];

        if (permission.types) {
            permission.types.split(',').forEach((type) => {
                dynamoTypes.push({ S: type });
            });
        }

        return dynamoTypes;
    }

    private permissionRoleActionsTransform(role: /*JsonProjectPermissionRoleData*/any): any/*DynamoProjectPermissionRoleActions*/ {
        const dynamoActions = { M: {} };

        if (role.actions) {
            role.actions.forEach((action: any) => {
                Object.keys(action).forEach((actionOrRole: any) => {
                    const actionOrRoleValues = [];

                    (typeof action[actionOrRole] === 'string') ? action[actionOrRole].split(',').forEach((value) => {
                        actionOrRoleValues.push({ S: value });
                    }) : action[actionOrRole].forEach((value) => {
                        actionOrRoleValues.push({ S: value });
                    })
                    ;

                    dynamoActions.M[actionOrRole] = {
                        L: actionOrRoleValues
                    };
                });
            });
        }

        return dynamoActions;
    }

    private permissionRoleOptionsTransform(role: /*JsonProjectPermissionRoleData*/any): /*DynamoProjectPermissionRoleOptions*/any {
        const dynamoOptions = { M: {} };

        if (role.options) {
            role.options.forEach((option) => {
                Object.keys(option).forEach((type: string) => {
                    dynamoOptions.M[type] = {
                        M: {
                            label: { S: option[type].label || 'null'},
                            optionPermissionTable: {
                                M: {
                                    idColumn: { S: option[type].optionPermissionTable.idColumn || 'null' },
                                    optionColumn: { S: option[type].optionPermissionTable.optionColumn || 'null'},
                                    table: { S: option[type].optionPermissionTable.table || 'null'}
                                }
                            },
                            optionTable: {
                                M: {
                                    idColumn: { S: option[type].optionTable.idColumn || 'null'},
                                    nameColumn: { S: option[type].optionTable.nameColumn || 'null'},
                                    permissionAction: { S: option[type].optionTable.permissionAction || 'null'},
                                    table: { S: option[type].optionTable.table || 'null'}
                                }
                            },
                            values: { S: option[type].values || 'null' }
                            // query: { S: option[type].query },
                            // values: { S: option[type].values }
                        }
                    };
                });
            });
        }

        return dynamoOptions;
    }
}
