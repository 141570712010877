import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { LibraryModule } from 'src/app/library/library.module';
import { DialogProjectFormComponent } from './dialog-project-form/dialog-project-form.component';
import { DialogRouteFormComponent } from './dialog-route-form/dialog-route-form.component';

@NgModule({
    declarations: [
        DialogProjectFormComponent,
        DialogRouteFormComponent
    ],
    imports: [
        LibraryModule,
        SharedModule
    ],
    providers: [],
    exports: [
        DialogProjectFormComponent,
        DialogRouteFormComponent
    ],
    entryComponents: []
})
export class DialogModule {}
