import { OnInit, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { tap } from 'rxjs/operators';
import { DialogComponent } from 'src/app/library/components/dialog/dialog.component';

@Component({
    selector: 'app-filter-query',
    templateUrl: './filter-query.component.html',
    styleUrls: ['./filter-query.component.css']
})
export class FilterQueryComponent {

    @Input() query;

    constructor(private dialog: MatDialog) {}

    public add(): void {
        this.query.filters.push({
            KEY: {
                alias: 'ALIAS',
                condition: 'CONDITION'
            }
        });
    }

    public xeditableChange(field: 'key'|'alias'|'condition', textChanged: string, filterIndex: number): void {
        const filters = this.query.filters;

        if (field === 'key') {
            filters[filterIndex] = {
                [textChanged]: Object.values(filters[filterIndex])[0]
            };
        } else {
            const key = Object.keys(filters[filterIndex])[0];
            filters[filterIndex][key][field] = textChanged;
        }
    }

    public delete(filterIndex) {
        const dialogRef = this.dialogDelete(this.query.filters[filterIndex], 'Êtes-vous sûr de supprimer ce filtre ?');

        dialogRef.afterClosed().pipe(
            tap((res) => {
                if (res) {
                    this.query.filters.splice(filterIndex, 1);
                }
            })
        ).subscribe();
    }

    private dialogDelete(dialogElement: any, dialogMessage: string) {
        return this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
                title: 'Supprimer',
                message: dialogMessage,
                element:  dialogElement,
                textButton: 'Supprimer'
            },
        });
    }
}
