import { OnInit, Component, Input } from '@angular/core';
import { DialogComponent } from '../../../library/components/dialog/dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';

@Component({
    selector: 'app-permission-show',
    templateUrl: './permission-show.component.html',
    styleUrls: ['./permission-show.component.css']
})
export class PermissionShowComponent implements OnInit {

    @Input() permission;
    public types = [];
    public resolvers = [];
    public roleOrActions = [];
    public modules = [];

    constructor(
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        if (this.permission.modules) {
            this.modules = this.permission.modules;
        }
        if (this.permission.types) {
            const types = this.permission.types.split(',');

            this.permission.types = this.permission.types.split(',');
            if (!this.permission.types.includes('null')) {
                this.permission.types.unshift('null');
                this.types.push({ key: 'null', value: 'null' });
            }

            this.permission.types = this.permission.types.toString();
            types.forEach((type) => {
                this.types.push({ key: type, value: type });
            });
        }
        if (this.permission.actions) {
            const actions = this.permission.actions.split(',');
            actions.forEach((action) => {
                this.roleOrActions.push({ key: action, value: action });
            });
        }
        if (this.permission.roles) {
            this.permission.roles.forEach((role) => {
                Object.keys(role).forEach((roleName) => {
                    this.roleOrActions.push({ key: roleName, value: roleName });
                });
            });
        }
        if (this.permission.resolvers) {
            this.permission.resolvers.forEach((resolver) => {
                Object.keys(resolver).forEach((resolverName) => {
                    this.resolvers.push({ key: resolverName, value: resolverName });
                });
            });
        }
    }

    xeditableChange(field: 'actions'|'types', textChanged: string): void {

        if (field === 'types') {
            this.types = [];
            const verificationDoublon = [];
            const correct = [];
            textChanged.split(',').forEach((type) => {
                if (!verificationDoublon.includes(type)) {
                    correct.push(type);
                    this.permission[field] = correct.toString();
                    this.types.push({ key: type, value: type });
                }
                verificationDoublon.push(type);

            });
        }
        if (field === 'actions') {
            this.permission[field] = textChanged;
            this.roleOrActions = [];
            textChanged.split(',').forEach((action) => {
                this.roleOrActions.push({ key: action, value: action });
            });
            if (this.permission.roles) {
                this.permission.roles.forEach((role) => {
                    Object.keys(role).forEach((roleName) => {
                        this.roleOrActions.push({ key: roleName, value: roleName });
                    });
                });
            }
        }
    }

    public addModule(nameModule) {
        this.modules.push({
            [nameModule]: {
                actions: 'null',
                query: {
                    permission: {
                        idColumn: 'null',
                        roleColumn: 'null',
                        table: 'null',
                        userColumn: 'null'
                    },
                    role: {
                        idColumn: 'null',
                        label: 'null',
                        nameColumn: 'null',
                        permissionAction: 'null',
                        table: 'null',
                    }
                },
                resolvers: [],
                roles: [],
                transformer: [],
                types: 'null'
            }
        });
        this.permission = Object.assign(this.permission, {modules: this.modules} );
    }

    public xeditableChangeModule(field: 'actions'|'types', textChanged: string, index: number, nameModule: string): void {
        this.modules[index][nameModule][field] = textChanged;
        this.permission = Object.assign(this.permission, {modules: this.modules} );
    }

    public deleteModule(modulesIndex: number) {
        const dialogRef = this.dialogDelete(this.modules[modulesIndex], 'Êtes-vous sûr de vouloir supprimer ce module ?');

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.modules.splice(modulesIndex, 1);
                this.permission = Object.assign(this.permission, {modules: this.modules} );
            }
        });
    }

    private dialogDelete(dialogElement: any, dialogMessage: string): MatDialogRef<DialogComponent, any> {
        return this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
                title: 'Supprimer',
                message: dialogMessage,
                element:  dialogElement,
                textButton: 'Supprimer'
            },
        });
    }
}
