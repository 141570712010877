import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ProjectApiService } from '../../../services/project-api.service';
import { DialogProjectFormComponent } from '../../../components/dialog/dialog-project-form/dialog-project-form.component';
import { JsonProject } from '../../../shared/transform/models/json-project.model';
import { switchMap, filter, concatMap, toArray } from 'rxjs/operators';

import { Router } from '@angular/router';
import { concat, from } from 'rxjs';

@Component({
    selector: 'app-project-table',
    templateUrl: './project-table.component.html',
    styleUrls: ['./project-table.component.css']
})
export class ProjectTableComponent implements OnInit {

    public projects: { key: string; name: string; }[] = [];

    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        public projectApiService: ProjectApiService) { }

    public ngOnInit(): void {
        this.projectApiService.getProjects().subscribe(
            (res: /*{key: string; name: string; }[]*/any) => {
            this.projects = res; console.log('Réponse:', res);
            });
    }

    public openDialogForm(): void {
        const dialogRef = this.dialog.open(DialogProjectFormComponent, {
            width: '700px',
            data: {}
        });
        const valueName = [];

        dialogRef.afterClosed()
            .pipe(
                filter(projectSubmited => projectSubmited),
                concatMap(
                    (projectSubmited: any) => {
                        projectSubmited.key = projectSubmited.id + '_' + projectSubmited.stage;
                        let projectName: any = '[' + projectSubmited.name + ']';
                        projectName = JSON.parse(projectName);
                        if (typeof (projectName) === 'object') {
                            projectName.map((name) => { valueName.push({ S: name }); });
                        } else {
                            valueName.push({ S: projectName });
                        }
                        projectSubmited.name = valueName;

                        return this.createTable(projectSubmited);
                    },
                    (projectSubmited: any, res) => {
                        return projectSubmited;
                    }
                ),
                concatMap(
                    (projectSubmited: any) => {
                        return this.projectApiService.postProject(projectSubmited);
                    },
                    (projectSubmited: any, res) => {
                        return projectSubmited;
                    }
                )
            )

            .subscribe(
                (projectSubmited) => {
                    this.snackBar.open('Projet créé avec succès', null, { duration: 2000 });
                    this.projects.push({ key: projectSubmited.key, name: projectSubmited.name.toString() });
                },
                () => {
                    this.snackBar
                        .open('Une erreur s\'est produite, veuillez réessayer ultérieurement', null, { duration: 2000 });
                }
            );
    }

    private createTable(projectSubmited: any) {
        return from(projectSubmited.name).pipe(
            concatMap(
                (name: any) => this.projectApiService.createDynamoDbTable(name.S)
            ),
            toArray()
        );
    }

}
