import { Injectable } from '@angular/core';
import {
    DynamoProjectRouteQueryColumns,
    DynamoProjectRouteQueryParameters,
    DynamoProjectRouteQueryConditions,
    DynamoProjectRouteQueryFilters,
    DynamoProjectRouteUpdate,
    DynamoProjectRouteQuery
} from '../models/dynamo-project-route.model';
import { JsonProjectRouteQuery, JsonProjectRoute, JsonProjectRouteJson } from '../models/json-project-route.model';

@Injectable()
export class JsonToDynamoProjectRouteTransform {

    public execute(jsonData: any/*JsonProjectRoute*/): any {
        const dynamodData: any /*DynamoProjectRouteUpdate*/ = {
            TableName: '',
            Key: { key: { S: jsonData.key } },
            AttributeUpdates: {
                parametersType: {
                    Action: 'PUT',
                    Value: { M: {} }
                },
                queries: {
                    Action: 'PUT',
                    Value: { L: [] }
                },
                result: {
                    Action: 'PUT',
                    Value: {
                        M: {
                            expression: { S: jsonData.result.expression },
                        }
                    }
                }
            }
        };

        if (jsonData.result.transformToObject) {
            dynamodData.AttributeUpdates.result.Value.M =
                Object.assign(dynamodData.AttributeUpdates.result.Value.M,
                    {transformToObject: { BOOL: jsonData.result.transformToObject }});

        }

        if (this.jsonTransform(jsonData.json).length > 0) {
            dynamodData.AttributeUpdates.json = {
                Action: 'PUT',
                Value: { L: this.jsonTransform(jsonData.json) }
            };
        }

        if (jsonData.result.indexes !== undefined) {
            dynamodData.AttributeUpdates.result.Value.M  = Object.assign(dynamodData.AttributeUpdates.result.Value.M,
                {
                indexes: { M: this.resultIndexesTransform(jsonData.result.indexes) }
            });
        }

        if (jsonData.result.lambda !== undefined) {
            dynamodData.AttributeUpdates.result.Value.M = Object.assign(dynamodData.AttributeUpdates.result.Value.M,
                {
                lambda: { M: this.resultLambdaTransform(jsonData.result.lambda) }
            });
        }

        jsonData.parametersType.forEach((parameter) => {
            Object.keys(parameter).forEach((parameterKey) => {
                dynamodData.AttributeUpdates.parametersType.Value.M[parameterKey] = { S: parameter[parameterKey] };
            });
        });

        if (jsonData.staticParameters.length > 1) {
            dynamodData.AttributeUpdates =
                Object.assign(dynamodData.AttributeUpdates,
                    {staticParameters: {
                        Action: 'PUT',
                        Value: { M: {} }
                    }});
            jsonData.staticParameters.forEach((parameter) => {
                Object.keys(parameter).forEach((parameterKey) => {
                    dynamodData.AttributeUpdates.staticParameters.Value.M[parameterKey] = { S: parameter[parameterKey] };
                });
            });
        }


        return this.queriesTransform(jsonData.queries, dynamodData);
    }

    private jsonTransform(json: any[]) {
        const dynamoJson = [];

        json.forEach((jsonItem: any) => {
            const dynamoParameters = { M: {} };
            const dynamoQuery = { M: {} };
            jsonItem.parameters.forEach((parameterValue) => {
                Object.keys(parameterValue).forEach((parameterKey: string) => {
                    dynamoParameters.M[parameterKey] = { S: parameterValue[parameterKey] };
                });
            });
            if (jsonItem.query !== undefined) {
                Object.keys(jsonItem.query).forEach((queryKey: string) => {
                    dynamoQuery.M[queryKey] = { S: jsonItem.query[queryKey] };
                });
                dynamoJson.push({
                    M: {
                        keyName: { S: jsonItem.keyName },
                        parameters: dynamoParameters,
                        query: dynamoQuery
                    }
                });
            } else {
                dynamoJson.push({
                    M: {
                        keyName: { S: jsonItem.keyName },
                        parameters: dynamoParameters
                    }
                });
            }
        });

        return dynamoJson;
    }

    private resultLambdaTransform(lambda: any) {
        const dynamoLambda = {
            data: { M: {} },
            functionName: { S : lambda.functionName },
            region: { S: lambda.region }
        };

        lambda.data.forEach((lambdaItem: any) => {
            const dynamoQuery = { M: {} };
            Object.keys(lambdaItem).forEach((lambaKey: string) => {
                dynamoQuery.M[lambaKey] = { S: lambdaItem[lambaKey] };
            });
            dynamoLambda.data.M = Object.assign(dynamoLambda.data.M, dynamoQuery.M);
        });

        return dynamoLambda;
    }

    private resultIndexesTransform(indexes: any[]) {
        let dynamoIndexes = {};

        indexes.forEach((indexesValue: any) => {
            const dynamoParameters = { M: {} };
            Object.keys(indexesValue).forEach((indexesKey: any) => {
                    const indexesKeyArray = [];
                    indexesValue[indexesKey].forEach((data) => {
                        indexesKeyArray.push({S: data});
                    });
                    dynamoParameters.M[Object.keys(indexesValue)[0]] = { L: indexesKeyArray };
                    dynamoIndexes = Object.assign(dynamoIndexes, dynamoParameters.M) ;
                });
        });

        return dynamoIndexes;
    }

    private columnsTransform(query: JsonProjectRouteQuery): any {
        const dynamoColumns = { M: {} };

        if (query.columns) {
            query.columns.forEach((column) => {
                Object.keys(column).forEach((columnKey) => {
                    dynamoColumns.M[columnKey] = { S: column[columnKey] };
                });
            });
        }

        return dynamoColumns;
    }

    private parametersTransform(query: JsonProjectRouteQuery): any {
        const dynamoParameters = { M: {} };

        if (query.parameters) {
            query.parameters.forEach((parameter) => {
                Object.keys(parameter).forEach((parameterKey) => {
                    dynamoParameters.M[parameterKey] = { S: parameter[parameterKey] };
                });
            });
        }

        return dynamoParameters;
    }

    private conditionsTransform(query: JsonProjectRouteQuery): any[] {
        const dynamoConditions = [];

        if (query.conditions) {
            query.conditions.forEach((condition) => {
                const dynamoCondition: any = {
                    M: {
                        type: { S: condition.type },
                    }
                };

                if (condition.parameter !== 'null') {
                    dynamoCondition.M.parameter = { S: condition.parameter };
                }
                if (condition.value !== 'null') {
                    dynamoCondition.M.value = { S: condition.value };
                }

                dynamoConditions.push(dynamoCondition);
            });
        }

        return dynamoConditions;
    }

    private filtersTransform(query: JsonProjectRouteQuery): any {
        const dynamoFilters = { M: {} };

        if (query.filters) {
            query.filters.forEach((filterQuery) => {
                Object.keys(filterQuery).forEach((filterQueryKey) => {
                    dynamoFilters.M[filterQueryKey] = {
                        M: {
                            alias: { S: filterQuery[filterQueryKey].alias },
                            condition: { S: filterQuery[filterQueryKey].condition }
                        }
                    };
                });
            });
        }

        return dynamoFilters;
    }

    private queriesTransform(queries: /*JsonProjectRouteQuery[]*/any, dynamodData: any): any/*DynamoProjectRouteUpdate*/ {
        queries.forEach((query) => {
            const dynamoRequired = { L: [] };
            if (query.required && query.required !== 'null') {
                query.required.split(',').forEach((field) => {
                    dynamoRequired.L.push({ S: field });
                });
            }

            const dynamoJsonColumns = { L: [] };
            if (query.jsonColumns && query.jsonColumns !== 'null')  {
                query.jsonColumns.split(',').forEach((jsonColumn) => {
                        dynamoJsonColumns.L.push({ S: jsonColumn });
                });
            }

            const dynamoQuery: any /*DynamoProjectRouteQuery*/ = {
                M: {
                    // parameters: this.parametersTransform(query),
                    // query: { S: query.query || 'null'},
                    // result: { S:  query.result || 'null'},
                    type: { S: query.type },
                }
            };
            if (query.type === 'select' || query.type === 'update' || query.type === 'insert' || query.type === 'lambda') {
                dynamoQuery.M.result = { S:  query.result };
            }
            if (query.type === 'select' || query.type === 'update' || query.type === 'insert') {
                dynamoQuery.M.query = { S:  query.query };
            }
            if (query.type === 'select' || query.type === 'update') {
                dynamoQuery.M.parameters = this.parametersTransform(query);
            }
            if (Object.entries(this.columnsTransform(query).M).length > 0) {
                dynamoQuery.M.columns = this.columnsTransform(query);
            }

            if (query.permissionAction !== 'null') {
                dynamoQuery.M.permissionAction = { S: query.permissionAction };
            }

            if (this.conditionsTransform(query).length > 0) {
                dynamoQuery.M.conditions = { L: this.conditionsTransform(query) };
            }
            if (query.data !== 'null' && query.data !== undefined) {
                dynamoQuery.M.data = { S: query.data };
            }
            if (Object.entries(this.filtersTransform(query).M).length > 0) {
                dynamoQuery.M.filters = this.filtersTransform(query);
            }
            if (dynamoJsonColumns.L.length > 0) {
                dynamoQuery.M.jsonColumns = dynamoJsonColumns;
            }
            if (dynamoRequired.L.length > 0) {
                dynamoQuery.M.required = dynamoRequired;
            }

            if (query.functionName !== 'null' && query.functionName !== undefined) {
                dynamoQuery.M.functionName = { S: query.functionName };
            }
            if (query.region !== 'null' && query.region !== undefined) {
                dynamoQuery.M.region = { S: query.region };
            }
            if (query.skipErrorPermission !== 'null' && query.skipErrorPermission !== undefined) {
                dynamoQuery.M.skipErrorPermission = { S: query.skipErrorPermission.toString() };
            }

            dynamodData.AttributeUpdates.queries.Value.L.push(dynamoQuery);
        });

        return dynamodData;
    }
}
