import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { tap } from 'rxjs/operators';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DialogComponent } from '../../../library/components/dialog/dialog.component';
import { JsonProjectRoute, JsonProjectRouteQuery } from '../../../shared/transform/models/json-project-route.model';
import { ProjectRouteApiService } from '../../../services/project-route-api.service';

@Component({
    selector: 'app-project-route-show',
    templateUrl: './project-route-show.component.html',
    styleUrls: ['./project-route-show.component.css']
})
export class ProjectRouteShowComponent implements OnInit {

    public projectRoute: /* JsonProjectRoute */ any = {
        json: [],
        key: '',
        parametersType: [],
        queries: [],
        result: {
            expression: '',
            transformToObject: false
        },
        staticParameters: []
    };
    public extra = {};
    public parameterTypeList = [
        { key: 'string', value: 'String' },
        { key: 'number', value: 'Number' },
        { key: 'array', value: 'Array' },
        { key: 'boolean', value: 'Boolean' },
        { key: 'select', value: 'Select' },
    ];
    public queryTypeList = [
        { key: 'insert', value: 'INSERT' },
        { key: 'select', value: 'SELECT' },
        { key: 'validation', value: 'VALIDATION' },
        { key: 'update', value: 'UPDATE' },
        { key: 'lambda', value: 'LAMBDA' },
    ];
    public routeParameters = [];
    public staticParameters = [];
    public open: boolean[];
    public openQueries: boolean[] = [];

    @ViewChild('input', { static: false }) inputRef: ElementRef;

    constructor(
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        public projectRouteApiService: ProjectRouteApiService,
        private location: Location) {
    }

    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id');
        const code = this.route.snapshot.paramMap.get('code');

        this.projectRouteApiService.getProjectRouteData(code, id)
            .subscribe((projectRoute: any) => {
                const tab = [];
                if (projectRoute.result.indexes) {
                    projectRoute.result.indexes.forEach((data) => {
                        tab.push(false);
                    });
                }
                this.projectRoute = projectRoute;
                this.projectRoute.parametersType.forEach((parameterType, value) => {
                    this.routeParameters.push({
                        key: Object.keys(parameterType)[0],
                        value: Object.keys(parameterType)[0]
                    });
                });
                this.open = tab;
                // this.projectRoute.staticParameters = [];
            });
    }

    public goBack(): void {
        this.location.back();
    }

    public addJson(): void {
        this.projectRoute.json.push({
            keyName: 'null',
            parameters: [],
        });
    }

    public deleteJson(jsonItemIndex): void {
        const dialogRef = this.dialogDelete(
            this.projectRoute.json[jsonItemIndex], 'Êtes-vous sûr de supprimer ce json ?');

        dialogRef.afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.projectRoute.json = this.projectRoute.json.filter((value, index) => index !== jsonItemIndex);
                }
            });
    }

    private dialogDelete(dialogElement: any, dialogMessage: string) {
        return this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
                title: 'Supprimer',
                message: dialogMessage,
                element: dialogElement,
                textButton: 'Supprimer'
            },
        });
    }

    public save() {
        this.projectRoute.queries.forEach((data) => {
            if (data.jsonColumns) {
                data.jsonColumns = data.jsonColumns.toString();
            }
        });
        this.changeQueryByType();
        const id = this.route.snapshot.paramMap.get('id');
        const code = this.route.snapshot.paramMap.get('code');

        if (!this.checkQueryType(this.projectRoute.queries)) {
            this.snackBar.open(
                'Erreur : Vérifiez que les requêtes SELECT UPDATE et INSERT ont bien une requête SQL correct',
                null, { duration: 5000 }
            );
            return;
        }

        this.projectRouteApiService.putProjectRouteData(code, id, this.projectRoute)
            .subscribe(
                () => this.snackBar.open('Route modifié avec succès', null, { duration: 2000 }),
                () => this.snackBar.open('Une erreur s\'est produite, veuillez réessayer ultérieurement', null, { duration: 2000 })
            );
    }

    public xeditableJsonParameterChange(
        field: 'keyName' | 'key' | 'value', textChanged: string, jsonItemIndex: number, jsonParameterIndex: number): void {
        switch (field) {
            case 'keyName':
                this.projectRoute.json[jsonItemIndex][textChanged] = Object.values(this.projectRoute.json[jsonItemIndex])[0];
                break;
            case 'key':
                this.projectRoute.json[jsonItemIndex].parameters[jsonParameterIndex] = {
                    [textChanged]: Object.values(this.projectRoute.json[jsonItemIndex].parameters[jsonParameterIndex])[0]
                };
                break;
            default:
                const parameterName = Object.keys(this.projectRoute.json[jsonItemIndex].parameters[jsonParameterIndex])[0];
                this.projectRoute.json[jsonItemIndex].parameters[jsonParameterIndex] = {
                    [parameterName]: textChanged
                };
                break;
        }
    }

    public addJsonParameter(jsonItemIndex: number): void {
        this.projectRoute.json[jsonItemIndex].parameters.push({
            null: 'null'
        });
    }

    public deleteJsonParameter(jsonItemIndex: number, jsonParameterIndex: number) {
        const dialogRef = this.dialogDelete(
            this.projectRoute.json[jsonItemIndex].parameters[jsonParameterIndex], 'Êtes-vous sûr de supprimer ce paramètre ?');

        dialogRef.afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.projectRoute.json[jsonItemIndex].parameters =
                        this.projectRoute.json[jsonItemIndex].parameters.filter((value, index) => index !== jsonParameterIndex);
                }
            });
    }

    private checkQueryType(queries: JsonProjectRouteQuery[]): boolean {
        let validation = true;

        queries.forEach((query) => {
            if (query.type === 'select' || query.type === 'insert' || query.type === 'update') {
                const sqlQuery = query.query.toLowerCase();

                if (!sqlQuery.includes(query.type)) {
                    validation = false;
                }
            }
        });

        return validation;
    }

    public addParameterType() {
        this.projectRoute.parametersType.push({
            PARAMETER_TYPE: 'string'
        });
        this.routeParameters.push({
            key: 'PARAMETER_TYPE',
            value: 'PARAMETER_TYPE'
        });
    }

    public deleteParameterType(deleteIndex: number) {
        const dialogRef = this.dialogDelete(this.projectRoute.parametersType[deleteIndex], 'Êtes-vous sûr de supprimer ce paramètre ?');

        dialogRef.afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.projectRoute.parametersType = this.projectRoute.parametersType.filter((value, index) => index !== deleteIndex);
                    this.routeParameters = this.routeParameters.filter((value, index) => index !== deleteIndex);
                }
            });
    }

    public xeditableParameterTypeChange(type: 'key' | 'value', textChanged: string, index: number) {
        switch (type) {
            case 'key':
                this.projectRoute.parametersType[index] = {
                    [textChanged]: Object.values(this.projectRoute.parametersType[index])[0]
                };
                this.routeParameters[index] = {
                    key: textChanged,
                    value: textChanged
                };
                break;
            case 'value':
                this.projectRoute.parametersType[index] = {
                    [Object.keys(this.projectRoute.parametersType[index])[0]]: textChanged
                };
                this.routeParameters[index] = {
                    [Object.keys(this.routeParameters[index])[0]]: textChanged
                };
                break;
            default:
                break;
        }
    }

    public addQuery() {
        this.openQueries.push(false);
        this.projectRoute.queries.push({
            jsonColumns: ['field'],
            parameters: [],
            permissionAction: 'PERMISSION_ACTION',
            filters: [],
            required: 'field',
            type: 'select',
            query: 'SELECT *',
            result: 'result',

            skipErrorPermission: true
        });
    }

    public changeQueryByType() {
        this.projectRoute.queries.forEach((query) => {
            switch (query.type) {
                case 'insert':
                    ['data', 'jsonColumns', 'filters', 'parameters', 'functionName', 'region', 'conditions']
                        .forEach(del => delete query[del]);
                    break;
                case 'lambda':
                    ['query', 'columns', 'jsonColumns', 'filters', 'parameters', 'conditions'].forEach(del => delete query[del]);
                    break;
                case 'select':
                    ['data', 'jsonColumns', 'functionName', 'region', 'conditions'].forEach(del => delete query[del]);
                    break;
                case 'update':
                    ['data', 'jsonColumns', 'functionName', 'region', 'conditions'].forEach(del => delete query[del]);
                    break;
                case 'validation':
                    ['result', 'data', 'jsonColumns', 'filters', 'parameters', 'query', 'functionName', 'region'].forEach(
                        del => delete query[del]);
                    break;
                default:
                    break;
            }
        });
    }

    public addJsoncolumsQueriesElement(newName: string, index: number) {
        this.projectRoute.queries[index].jsonColumns.push(newName);

        this.openQueries[index] = false;
    }

    public editJsoncolumsQueries(index: number) {
        this.openQueries[index] ? (this.openQueries[index] = false) : (this.openQueries[index] = true);
        if (this.openQueries[index]) {
            requestAnimationFrame(
            () => {this.inputRef.nativeElement.focus(); });
        }
    }

    showJsoncolumsQueries(info: string, index: number, i: number) {
        this.projectRoute.queries[index].jsonColumns[i] = info;
        this.openQueries[index] = false;
    }

    public deleteJsoncolumsQueriesElement(index: number, i: number) {
        this.projectRoute.queries[index].jsonColumns.splice(i, 1);
        this.openQueries[index] = false;
    }
    public deleteQuery(queryIndex: number) {
        const dialogRef = this.dialogDelete(this.projectRoute.queries[queryIndex], 'Êtes-vous sûr de supprimer cette requête ?');

        dialogRef.afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.projectRoute.queries = this.projectRoute.queries.filter((value, index) => index !== queryIndex);
                }
            });
    }

    public xeditableQueryChange(field: 'type' | 'query' | 'result', textChanged: string, index: number) {
        this.projectRoute.queries[index][field] = textChanged;

        if (field === 'type') {
            switch (textChanged) {
                case 'select':
                    this.projectRoute.queries[index].parameters = [];
                    this.projectRoute.queries[index].filters = [];
                    this.projectRoute.queries[index].jsonColumns = ['field'];
                    break;
                case 'insert':
                    this.projectRoute.queries[index].columns = [];
                    break;
                case 'update':
                    this.projectRoute.queries[index].columns = [];
                    this.projectRoute.queries[index].filters = [];
                    this.projectRoute.queries[index].parameters = [];
                    break;
                case 'validation':
                    this.projectRoute.queries[index].data = 'DATA';
                    this.projectRoute.queries[index].conditions = [];
                    break;
                case 'lambda':
                    this.projectRoute.queries[index].data = this.projectRoute.queries[index].data || 'DATA';
                    this.projectRoute.queries[index].functionName = this.projectRoute.queries[index].functionName || 'FunctionName';
                    this.projectRoute.queries[index].region = this.projectRoute.queries[index].region || 'Region';
                    this.projectRoute.queries[index].conditions = [];
                    break;
                default:
                    break;
            }
        }
    }

    public xeditableResultChange(field: 'expression', textChanged: string) {
        this.projectRoute.result[field] = textChanged;
    }

    // StaticParameters
    public addStaticParameters() {
        this.projectRoute.staticParameters.push({
            KEY: 'value'
        });
        this.staticParameters.push({
            key: 'KEY',
            value: 'VALUE'
        });
    }

    public deleteStaticParameters(deleteIndex: number) {
        const dialogRef = this.dialogDelete(this.projectRoute.staticParameters[deleteIndex], 'Êtes-vous sûr de supprimer ce paramètre ?');

        dialogRef.afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.projectRoute.staticParameters = this.projectRoute.staticParameters.filter((value, index) => index !== deleteIndex);
                    this.staticParameters = this.staticParameters.filter((value, index) => index !== deleteIndex);
                }
            });
    }

    public xeditableStaticParametersChange(type: 'key' | 'value', textChanged: string, index: number) {
        switch (type) {
            case 'key':
                this.projectRoute.staticParameters[index] = {
                    [textChanged]: Object.values(this.projectRoute.staticParameters[index])[0]
                };
                this.staticParameters[index] = {
                    key: textChanged,
                    value: textChanged
                };
                break;
            case 'value':
                this.projectRoute.staticParameters[index] = {
                    [Object.keys(this.projectRoute.staticParameters[index])[0]]: textChanged
                };
                this.staticParameters[index] = {
                    [Object.keys(this.staticParameters[index])[0]]: textChanged
                };
                break;
            default:
                break;
        }
    }

    // JsonQuerry
    public xeditableJsonQueryChange(
        field: 'idColumnName' | 'idParameterName' | 'jsonColumnName' | 'tableName', textChanged: string, jsonItemIndex: number): void {
        this.projectRoute.json[jsonItemIndex].query[field] = textChanged;
    }

    public addJsonQuery(jsonItemIndex: number): void {

        if (this.projectRoute.json[jsonItemIndex].query === undefined) {
            this.projectRoute.json[jsonItemIndex] = Object.assign(this.projectRoute.json[jsonItemIndex], {
                query: {
                    idColumnName: 'null',
                    idParameterName: 'null',
                    jsonColumnName: 'null',
                    tableName: 'null',
                }
            });
        }
    }

    public deleteJsonQuery(jsonItemIndex: number) {

        const dialogRef = this.dialogDelete(
            this.projectRoute.json[jsonItemIndex].query, 'Êtes-vous sûr de supprimer ce paramètre ?');

        dialogRef.afterClosed()
            .subscribe((res) => {
                if (res) {
                    delete this.projectRoute.json[jsonItemIndex].query;
                }
            });
    }

    // ResultTansform
    public xeditableResultTransformChange(event: boolean) {
        this.projectRoute.result.transformToObject = event;
    }

    // ResultLambda
    public xeditableResultLambdaChange(type: string, textChanged: string) {
        this.projectRoute.result.lambda[type] = textChanged;
    }

    public addResultLambda(): void {
        if (this.projectRoute.result.lambda === undefined) {
            this.projectRoute.result = Object.assign(this.projectRoute.result, {
                lambda: {
                    data: [],
                    functionName: 'null',
                    region: 'null',
                }
            });
        }
    }

    public deleteResultLambda() {
        const dialogRef = this.dialogDelete(
            this.projectRoute.result.lambda, 'Êtes-vous sûr de supprimer ce paramètre ?');

        dialogRef.afterClosed()
            .subscribe((res) => {
                if (res) {
                    delete this.projectRoute.result.lambda;
                }
            });
    }

    public addResultLambdaData() {
        this.projectRoute.result.lambda.data.push({
            KEY: 'value'
        });
    }

    public deleteResultLambdaData(deleteIndex: number) {
        const dialogRef = this.dialogDelete(this.projectRoute.result.lambda.data[deleteIndex], 'Êtes-vous sûr de supprimer ce paramètre ?');

        dialogRef.afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.projectRoute.result.lambda.data =
                        this.projectRoute.result.lambda.data.filter((value, index) => index !== deleteIndex);
                }
            });
    }

    public xeditableResultLambdaDataChange(type: 'key' | 'value', textChanged: string, index: number) {
        switch (type) {
            case 'key':
                this.projectRoute.result.lambda.data[index] = {
                    [textChanged]: Object.values(this.projectRoute.result.lambda.data[index])[0]
                };
                break;
            case 'value':
                this.projectRoute.result.lambda.data[index] = {
                    [Object.keys(this.projectRoute.result.lambda.data[index])[0]]: textChanged
                };
                break;
            default:
                break;
        }
    }

    // ResultIndexes
    public xeditableResultIndexesChange(type: 'key' | 'value', textChanged: string, index: number) {
        switch (type) {
            case 'key':
                this.projectRoute.result.indexes[index] = {
                    [textChanged]: Object.values(this.projectRoute.result.indexes[index])[0]
                };
                break;
            case 'value':  // never use
                this.projectRoute.result.indexes[index] = {
                    [Object.keys(this.projectRoute.result.indexes[index])[0]]: textChanged
                };
                break;
            default:
                break;
        }
    }

    public addResultIndexes(): void {
        if (this.projectRoute.result.indexes === undefined) {
            this.projectRoute.result = Object.assign(this.projectRoute.result, {
                indexes: [{ KEY: ['null'] }]
            });
            this.open = [false];
        }
    }

    public addResultIndexesElement() {
        this.projectRoute.result.indexes.push({
            KEY: ['value', 'name']
        });
        this.open.push(false);
    }

    public deleteResultIndexesElement(deleteIndex: number) {
        const dialogRef = this.dialogDelete(
            this.projectRoute.result.indexes[deleteIndex], 'Êtes-vous sûr de supprimer ce paramètre ?');

        dialogRef.afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.projectRoute.result.indexes =
                        this.projectRoute.result.indexes.filter((value, index) => index !== deleteIndex);
                }
            });
    }

    public deleteResultIndexes() {
        const dialogRef = this.dialogDelete(
            this.projectRoute.result.indexes, 'Êtes-vous sûr de supprimer ce paramètre ?');

        dialogRef.afterClosed()
            .subscribe((res) => {
                if (res) {
                    delete this.projectRoute.result.indexes;
                }
            });
    }

    public addResultIndexesData(newName: string, index: number, key: string) {
        this.projectRoute.result.indexes[index][key].push(newName);
        this.open[index] = false;
    }

    public edit(index: number) {
        this.open[index] ? (this.open[index] = false) : (this.open[index] = true);
        if (this.open[index]) {
            requestAnimationFrame(
            () => {this.inputRef.nativeElement.focus(); });
        }
    }

    show(info: string, index: number, i: number, key: string) {
        this.projectRoute.result.indexes[index][key][i] = info;
        this.open[index] = false;
    }

    public deleteName(index: number, i: number, key: string) {
        this.projectRoute.result.indexes[index][key].splice(i, 1);
        this.open[index] = false;
        // if (this.project.name.length > 1) {
        //     this.project.name.splice(i, 1);
        //     this.setProjectName();
        //     this.open = false;
        // } else {
        //     this.snackBar.open('Il est obligatoire d\'avoir au moins un nom', null, { duration: 2000 });
        // }
    }

    // Toggle
    public toggleLamba(event: boolean) {
        event ? this.addResultLambda() : this.deleteResultLambda();
    }

    public toggleIndexes(event: boolean) {
        event ? this.addResultIndexes() : this.deleteResultIndexes();
    }

    public toggleJsonQuery(event: boolean, jsonItemIndex: number) {

        event ? this.addJsonQuery(jsonItemIndex) : this.deleteJsonQuery(jsonItemIndex);
    }

}
