import { NgModule } from '@angular/core';
import { ActionShowComponent } from './action-show/action-show.component';
import { OptionShowComponent } from './option-show/option-show.component';
import { TransformerShowComponent } from './transformer-show/transformer-show.component';
import { RoleShowComponent } from './role-show/role-show.component';
import { ResolverShowComponent } from './resolver-show/resolver-show.component';
import { PermissionShowComponent } from './permission-show/permission-show.component';
import { RoleFilterPipe } from './role-show/role-filter.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { LibraryModule } from 'src/app/library/library.module';
import { DialogComponent } from 'src/app/library/components/dialog/dialog.component';
import { DialogInputComponent } from 'src/app/library/components/dialog-input/dialog-input.component';
import { QueryShowComponent } from './query-show/query-show-component';

@NgModule({
    declarations: [
        ActionShowComponent,
        OptionShowComponent,
        TransformerShowComponent,
        RoleShowComponent,
        ResolverShowComponent,
        PermissionShowComponent,
        RoleFilterPipe,
        QueryShowComponent,
    ],
    imports: [
        LibraryModule,
        SharedModule
    ],
    providers: [],
    exports: [
        ActionShowComponent,
        OptionShowComponent,
        TransformerShowComponent,
        RoleShowComponent,
        ResolverShowComponent,
        PermissionShowComponent,
        RoleFilterPipe,
        QueryShowComponent,
    ],
    entryComponents: [
        DialogComponent,
        DialogInputComponent
    ]
})
export class PermissionModule {}
