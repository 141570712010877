import { OnInit, Component, Input, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { tap, startWith, map } from 'rxjs/operators';
import { MatDialogRef, MatDialog, MatAutocomplete, MatChipInputEvent, MatAutocompleteSelectedEvent } from '@angular/material';
import { DialogComponent } from 'src/app/library/components/dialog/dialog.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
    selector: 'app-action-show',
    templateUrl: './action-show.component.html',
    styleUrls: ['./action-show.component.css']
})
export class ActionShowComponent implements OnInit, OnChanges {

    @Input() actions;
    @Input() roleOrActions;
    @Input() resolvers;
    @Input() role;
    @Input() listRole;
    @Input() transformers;
    public roleOrActionsList = [];
    public resolverList = [];

    public roleList = []; // ok
    public transformerList = [];
    public valueType: boolean[] = [];

    constructor(
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.actions.forEach((data, index) => {
            this.actions[index][Object.keys(data)[0]] = this.actions[index][Object.keys(data)[0]].split(',');
            this.valueType.push(true);
        });
        if (this.listRole) {
            this.listRole.forEach((data) => {
                if (Object.keys(data)[0] !== this.role) {
                    this.roleList.push({ key: Object.keys(data)[0], value: Object.keys(data)[0] });
                }
            });
        }
        if (this.transformers) {
            this.transformers.forEach((data) => {
                this.transformerList.push({ key: Object.keys(data)[0], value: Object.keys(data)[0] });
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.roleOrActions) {
            this.roleOrActionsList = [];

            changes.roleOrActions.currentValue.split(',').forEach((action) => {
                this.roleOrActionsList.push({ key: action, value: action });
            });
        }
        if (changes.resolvers || this.resolvers) {
            this.resolverList = [];
            this.resolvers.forEach((resolver) => {
                Object.keys(resolver).forEach((resolverName) => {
                    this.resolverList.push({ key: resolverName, value: resolverName });
                });
            });
        }
    }

    public toggleRoleAction(event: boolean, transformerIndex: number) {
        this.valueType[transformerIndex] = event;
    }

    public add(): void {
        this.actions.push({
            ACTION_OR_ROLE: ''
        });
    }

    public delete(roleActionIndex: number): void {
        const dialogRef = this.dialogDelete(this.actions[roleActionIndex], 'Êtes-vous sûr de vouloir supprimer cette action ?');

        dialogRef.afterClosed().pipe(
            tap((res) => {
                if (res) {
                    this.actions.splice(roleActionIndex, 1);
                }
            })
        ).subscribe();
    }

    public xeditablChange(field: 'key'|'value', textChanged: string, roleActionIndex: number): void {
        if (field === 'key') {
            this.actions[roleActionIndex] = {
                [textChanged]: Object.values(this.actions[roleActionIndex])[0]
            };
        } else {
            const roleName = Object.keys(this.actions[roleActionIndex])[0];

            this.actions[roleActionIndex][roleName] = textChanged;
        }
    }

    private dialogDelete(dialogElement: any, dialogMessage: string): MatDialogRef<DialogComponent, any> {
        return this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
                title: 'Supprimer',
                message: dialogMessage,
                element:  dialogElement,
                textButton: 'Supprimer'
            },
        });
    }
}
