import { OnInit, Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { tap } from 'rxjs/operators';
import { DialogComponent } from 'src/app/library/components/dialog/dialog.component';

@Component({
    selector: 'app-parameter-query',
    templateUrl: './parameter-query.component.html',
    styleUrls: ['./parameter-query.component.css']
})
export class ParameterQueryComponent {

    @Input() query;
    public parameterTypeList = [
        { key: 'string', value: 'String' },
        { key: 'number', value: 'Number' },
        { key: 'array', value: 'Array' },
        { key: 'boolean', value: 'Boolean' },
    ];

    constructor(private dialog: MatDialog) {}

    public add(): void {
        this.query.parameters.push({
            KEY: 'value'
        });
    }

    public xeditableChange(type: 'key'|'value', textChanged: string, parameterIndex: number) {
        if (type === 'key') {
            this.query.parameters[parameterIndex] = {
                [textChanged]: Object.values(this.query.parameters[parameterIndex])[0]
            };
        }
        if (type === 'value') {
            this.query.parameters[parameterIndex] = {
                [Object.keys(this.query.parameters[parameterIndex])[0]]: textChanged
            };
        }
    }

    public delete(parameterIndex): void {
        const dialogRef = this.dialogDelete(this.query.parameters[parameterIndex], 'Êtes-vous sûr de supprimer ce paramètre ?');

        dialogRef.afterClosed().pipe(
            tap((res) => {
                if (res) {
                    this.query.parameters.splice(parameterIndex, 1);
                }
            })
        ).subscribe();
    }

    private dialogDelete(dialogElement: any, dialogMessage: string): MatDialogRef<DialogComponent, any> {
        return this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
                title: 'Supprimer',
                message: dialogMessage,
                element:  dialogElement,
                textButton: 'Supprimer'
            },
        });
    }
}
