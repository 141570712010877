import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'app-dialog-route-form',
    templateUrl: './dialog-route-form.component.html',
})
export class DialogRouteFormComponent {

    public routeForm: FormGroup;
    public projectCodeSelect;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<DialogRouteFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        this.routeForm = this.formBuilder.group({
            key: [null, Validators.required],
            code: [data.info[0], Validators.required]
        });
        this.projectCodeSelect = data.info;

    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
