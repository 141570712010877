import { Component } from '@angular/core';
import { ConnectionService } from './shared/services/connection.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(public connectionService: ConnectionService) { }
}
