import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'app-dialog-project-form',
    templateUrl: './dialog-project-form.component.html',
})
export class DialogProjectFormComponent {

    public projectForm: FormGroup;
    public projects: { key: string; name: string; }[] = [];
    public projectName = [];
    public typesDB = [{value: 'mysql', text: 'MySQL'}, { value: 'pgsql', text: 'PostgreSQL'}];

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<DialogProjectFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        this.projectForm = this.formBuilder.group({
            // key: [null, Validators.required],
            id: [null, Validators.required],
            stage: [null, Validators.required],
            name: [null, Validators.required],
            connection: this.formBuilder.group({
                database: [null, Validators.required],
                host: [null, Validators.required],
                password: [null, Validators.required],
                type: [null, Validators.required],
                user: [null, Validators.required]
            }),
            user: [null]
        });
    }

    onNoClick = async (): Promise<any> => {
        // this.dialogRef.close();
        // this.projectForm.value.key = this.projectForm.value.id + '/' + this.projectForm.value.stage;
        return this.projectForm.value;
    }

    private setProjectForm() {
        this.projectForm.setValue({
            id: this.projectForm.controls.id.value || null,
            stage: this.projectForm.controls.stage.value,
            name: this.projectName.toString(),
            connection: this.projectForm.controls.connection.value,
            user: this.projectForm.controls.user.value});
    }

    public addName(newName: string) {
        if (newName.length >= 1) {
            this.projectName.push('\"' + newName.trim() + '\"');
            this.setProjectForm();
        }
    }

    public deleteName(i) {
        this.projectName.splice(i, 1);
        this.setProjectForm();
    }
}
