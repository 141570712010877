import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatToolbarModule,
    MatDividerModule,
    MatChipsModule,
} from '@angular/material';
import { AwsService } from './services/aws.service';
import { ConnectionService } from './services/connection.service';
import { DynamoToJsonProjectRouteTransform } from './transform/project-route/dynamo-to-json-project-route.transform';
import { JsonToDynamoProjectRouteTransform } from './transform/project-route/json-to-dynamo-project-route.transform';
import { JsonToDynamoProjectTransform } from './transform/project/json-to-dynamo-project.transform';
import { DynamoToJsonProjectTransform } from './transform/project/dynamo-to-json-project.transform';
import { JsonProjectVerfication } from './transform/project-route/json-verification';

const MODULES = [
    MatAutocompleteModule,
    MatDividerModule,
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    // MatButtonToggleModule,
    // MatCardModule,
    MatCheckboxModule,
    // MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    // MatNativeDateModule,
    MatPaginatorModule,
    // MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatToolbarModule,
    MatChipsModule,
];

@NgModule({
    declarations: [],
    imports: [
        MODULES
    ],
    providers: [
        AwsService,
        ConnectionService,
        JsonToDynamoProjectRouteTransform,
        DynamoToJsonProjectRouteTransform,
        JsonToDynamoProjectTransform,
        DynamoToJsonProjectTransform,
        JsonProjectVerfication
    ],
    exports: [
        CommonModule,
        MODULES
    ]
})
export class SharedModule { }
