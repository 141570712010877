import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/library/services/api.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { map } from 'rxjs/operators';
import { DynamoToJsonProjectTransform } from 'src/app/shared/transform/project/dynamo-to-json-project.transform';
import { JsonToDynamoProjectTransform } from 'src/app/shared/transform/project/json-to-dynamo-project.transform';
import { JsonProject } from 'src/app/shared/transform/models/json-project.model';
import { DynamoProject, DynamoProjects } from '../shared/transform/models/dynamo-project.model';
import { JsonProjectVerfication } from 'src/app/shared/transform/project-route/json-verification';
// JsonProjectVerfication
@Injectable()
export class ProjectApiService extends ApiService {

    constructor(
        protected http: HttpClient,
        protected connectionService: ConnectionService,
        protected dynamoToJsonProjectTransform: DynamoToJsonProjectTransform,
        protected jsonToDynamoProjectTransform: JsonToDynamoProjectTransform,
        protected jsonProjectVerfication: JsonProjectVerfication) {
        super(http, connectionService);
    }

    public getProjects(): Observable<{ key: string; name: string; }[]> {
        const options = this.getOptions();

        return this.http.get(this.apiUrl + '/projects', options).pipe(
            map((dynamoProjects: any) => {
                const projects: { key: string; name: string; }[] = [];

                dynamoProjects.Items.forEach((dyanmoProject) => {
                    const nameProject = [];
                    if (dyanmoProject.name.L !== undefined) {
                        dyanmoProject.name.L.forEach((data: any) => {nameProject.push(data.S); });
                    }
                    projects.push({
                        key: dyanmoProject.key.S,
                        name: dyanmoProject.name.S || nameProject.toString()
                    });
                });

                return projects;
            })
        );
    }

    public getProjectData(id: string): Observable<JsonProject> {
        return this.http.get(`${this.apiUrl}/projects/${id}`, this.getOptions()).pipe(
            map((dynamoProjects: any) => {
                return this.dynamoToJsonProjectTransform.execute(dynamoProjects);
            })
        );
    }

    public putProjectData(id: string, jsonProject: JsonProject): Observable<any> {
        if (this.jsonProjectVerfication.execute(jsonProject)) {
            const dynamoProject = this.jsonToDynamoProjectTransform.executePutItem2(jsonProject);
            dynamoProject.TableName = 'project';

            return this.http.put(`${this.apiUrl}/projects/${id}`, dynamoProject, this.getOptions());
        }
        return of('ErrorJson');
    }

    public postProject(project: any): Observable<any> {
        const dynamoProject = this.jsonToDynamoProjectTransform.executePostItem2(project);
        return this.http.post(`${this.apiUrl}/projects`, dynamoProject, this.getOptions());
    }

    public createDynamoDbTable(projectCode: string): Observable<any> {
        const body = {
            TableName: projectCode,
            KeySchema: [
                {
                    AttributeName: 'key',
                    KeyType: 'HASH'
                }
            ],
            AttributeDefinitions: [
                {
                    AttributeName: 'key',
                    AttributeType: 'S'
                }
            ],
            ProvisionedThroughput: {
               ReadCapacityUnits: 5,
               WriteCapacityUnits: 5
            }
        };
        return this.http.post(`${this.apiUrl}/projects/${projectCode}/table`, body, this.getOptions());
    }
}
