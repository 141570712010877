import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatOption, MatSelect } from '@angular/material';
import { Observable } from 'rxjs';
import { EditableService } from '../../services/editable.service';
import { FormControl } from '@angular/forms';

export interface EditableSelectDataType {
    key: string;
    value: string;
}
@Component({
    selector: 'app-editable',
    templateUrl: './editable.component.html',
    styleUrls: ['./editable.component.css']
})
export class EditableComponent implements OnInit {

    public open = false;

    private data = {};
    public tab = [];

    @Input() dataAsync: Observable<EditableSelectDataType[]>;
    @Input() label: string;
    @Input() name: string;
    @Input() pk: number;
    @Input() pkkey: string;
    @Input() text: string;
    @Input() type: string;
    @Input() url: string;
    @Input() value: string;
    @Input() unique: boolean;
    @Input() static: string;
    @Input() multiple: boolean;
    @Output() textChange = new EventEmitter<string>();
    @ViewChild('input', { static: false }) inputRef: ElementRef;
    @ViewChild('select', { static: false }) selectRef: MatSelect;
    selectMultipe = new FormControl();

    constructor(private editableService: EditableService) { }

    ngOnInit() {
        this.data = {
            [this.pkkey]: this.pk,
            [this.name]: null
        };
        if (this.type === 'array') {
            this.tab = this.text.split(',');
        }
        if (this.type === 'select' && this.multiple) {
            this.dataAsync.forEach((data: any) => {
                this.tab.push(data.key);
            });
        }
    }

    edit() {
        this.open = true;
        if (this.type === 'text') {
            requestAnimationFrame(() => {
                this.inputRef.nativeElement.focus();
            });
        }
    }

    show(status: boolean) {
        if (!status) {
            this.open = false;
            return;
        }

        this.text = this.getText();
        this.value = this.getData();
        this.textChange.emit(this.value);
        this.open = false;
        // if (!status) {
        //     this.open = false;
        //     return;
        // }
        // const text = this.getText();
        // const value = this.getData();

        // const self = this;
        // this.data[this.name] = value;
        // this.editableService.editable(this.url, this.data).subscribe((response: any) => {
        //     if (response.success) {
        //         self.text = text;
        //         self.value = value;
        //         self.textChange.emit(self.text);
        //     }

        //     this.open = false;
        // });
    }

    private getData() {
        if (this.type === 'text' || this.type === 'textarea') {
            return this.inputRef.nativeElement.value;
        }

        if (this.type === 'select') {
            return this.selectRef.value;
        }
        if (this.type === 'array') {
           // this.tab =  this.inputRef.nativeElement.value.split(',');
            return  this.inputRef.nativeElement.value;
        }

        throw Error('Unknown type');
    }

    private getText() {
        if (this.type === 'text' || this.type === 'textarea') {
            return this.inputRef.nativeElement.value;
        }

        if (this.type === 'select') {
            return (this.selectRef.selected as MatOption).viewValue;
        }
        if (this.type === 'array') {
            this.tab =  this.inputRef.nativeElement.value.split(',');
            return  this.inputRef.nativeElement.value;
        }

        throw Error('Unknown type');
    }

    public addText(newName: string) {
        if (!this.tab.includes(newName) && this.unique) {
            this.tab.push(newName);
        } else if (!this.unique ) {
            this.tab.push(newName);
        }
        this.text = this.tab.toString();
        this.open = false;

        this.textChange.emit(this.text);
    }

    public editText() {
        if (this.open) {
            this.open = false;
        } else {
            this.open = true;
            requestAnimationFrame(() => {
                this.inputRef.nativeElement.focus();
            });
        }
    }

    public showText(info: string, i: number) {
        // if (!status) {
        //     this.open = false;
        //     return;
        // }

        this.value = this.getData();
        if (!this.tab.includes(info) && this.unique) {
            this.tab[i] = info;
        } else if (!this.unique ) {
            this.tab[i] = info;
        }
        this.text = this.tab.toString();
        this.open = false;
        this.textChange.emit(this.text);

    }

    public deleteText(i) {
        if (this.tab.length > 1) {
            this.tab.splice(i, 1);
            this.text = this.tab.toString();
            this.open = false;
            this.textChange.emit(this.text);
        } else {
            // this.snackBar.open('Il est obligatoire d\'avoir au moins un nom', null, { duration: 2000 });
        }
    }
}
