import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { tap } from 'rxjs/operators';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { ProjectApiService } from 'src/app/services/project-api.service';
import { JsonProject } from 'src/app/shared/transform/models/json-project.model';
import { DialogComponent } from 'src/app/library/components/dialog/dialog.component';

@Component({
    selector: 'app-project-show',
    templateUrl: './project-show.component.html',
    styleUrls: ['./project-show.component.css']
})
export class ProjectShowComponent implements OnInit {

    public project;
    public open = false;
    public projectName;
    public extra = {
        projectCode: null
    };

    @ViewChild('input', { static: false }) inputRef: ElementRef;

    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        public projectApiService: ProjectApiService,
        private snackBar: MatSnackBar,
        private location: Location) {
            this.extra = {
                projectCode: this.route.snapshot.paramMap.get('code')
            };
        }

    ngOnInit(): void {
        const id = this.route.snapshot.paramMap.get('id');

        this.projectApiService.getProjectData(id)
            .subscribe((project: JsonProject) => {
                this.project = project;
                const valueName = [];
                if (Array.isArray(project.name)) {
                    project.name.map((name) => { valueName.push(name.S); });
                    this.project.name = valueName;
                } else {
                    this.project.name = [project.name];
                }
                this.setProjectName();
            });
    }

    public goBack(): void {
        this.location.back();
    }

    public addPermission() {
        this.project.permission = {
            actions: 'null',
            query: {
                permission: {
                    idColumn: 'id',
                    roleColumn: 'role_id',
                    table: 'permission',
                    userColumn: 'user_id'
                },
                role: {
                    idColumn: 'id',
                    label: 'label',
                    nameColumn: 'role',
                    permissionAction: 'READ_GRANT_ROLE',
                    table: 'permission_role'
                }
            },
            resolvers: [],
            roles: [],
            types: 'null',
            transformer: []
        };
    }

    public deletePermission() {
        const dialogRef = this.dialogDelete(this.project.permission,
            'Êtes-vous sûr de vouloir supprimer les permission pour ce projet?');

        dialogRef.afterClosed()
            .subscribe(
                (deletePermission) => {
                    if (deletePermission) {
                        delete this.project.permission;
                        this.snackBar.open('Permission supprimer', null, { duration: 2000 });
                    }
                },
                () => this.snackBar.open('Une erreur s\'est produite, veuillez réessayer ultérieurement', null, { duration: 2000 })
            );
    }

    private dialogDelete(dialogElement: any, dialogMessage: string): MatDialogRef<DialogComponent, any> {
        return this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
                title: 'Supprimer',
                message: dialogMessage,
                element:  dialogElement,
                textButton: 'Supprimer'
            },
        });
    }

    public xeditableChange(field: 'name'|'user', textChanged: string): void {
        this.project[field] = textChanged;
    }

    public xeditableConnectionChange(field: 'database'|'host'|'user'|'password', textChanged: string): void {
        this.project.connection[field] = textChanged;
    }

    public save(): void {
        const id = this.route.snapshot.paramMap.get('id');

        this.projectApiService.putProjectData(id, this.project)
            .subscribe(
            (res) => {
                (res === 'ErrorJson') ? this.snackBar.open('Format Json Incorrecte', null, { duration: 2000 })
                    : this.snackBar.open('Projet modifié avec succès', null, { duration: 2000 });
            },
            () => this.snackBar.open('Une erreur s\'est produite, veuillez réessayer ultérieurement', null, { duration: 2000 })
        );
    }
    public setProjectName() {
        this.projectName = this.project.name.join('; ');
    }

    public addName(newName: string) {
        this.project.name.push(newName);
        this.setProjectName();
        this.open = false;
    }

    public edit() {
        if (this.open) {
            this.open = false;
        } else {
            this.open = true;
            requestAnimationFrame(() => {
                this.inputRef.nativeElement.focus();
            });
        }
    }

    show(info: string, i) {
        this.project.name[i] = info;
        this.setProjectName();
        this.open = false;
    }

    public deleteName(i) {
        if (this.project.name.length > 1) {
            this.project.name.splice(i, 1);
            this.setProjectName();
            this.open = false;
        } else {
            this.snackBar.open('Il est obligatoire d\'avoir au moins un nom', null, { duration: 2000 });
        }
    }
}
