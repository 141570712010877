import { OnInit, Component, Input, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatAutocomplete, MatChipInputEvent, MatAutocompleteSelectedEvent } from '@angular/material';
import { DialogComponent } from 'src/app/library/components/dialog/dialog.component';
import { tap, startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
    selector: 'app-resolver-show',
    templateUrl: './resolver-show.component.html',
    styleUrls: ['./resolver-show.component.css']
})
export class ResolverShowComponent implements OnInit, OnChanges {

    @Input() resolvers;
    @Input() types;
    public typesList = [];

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];

    typeCtrl = new FormControl();
    filteredTypes: Observable<string[]>;

    @ViewChild('typeInput', { static: false }) typeInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

    constructor(
        public dialog: MatDialog
    ) {
        this.filteredTypes = this.typeCtrl.valueChanges.pipe(
            startWith(null),
            map((type: string | null) => {
                const typeTab = [];
                this.typesList.forEach((types) => {
                    typeTab.push(types.value);
                });
                const typeFiltered = type ? this.filterAutocomplete(type) : typeTab.slice();
                return typeFiltered;
            }));
    }

    ngOnInit(): void {
        this.resolvers.forEach((data) => {
            const name = Object.keys(data)[0];
            if (typeof data[name].types === 'string') {
                data[name].types = data[name].types.split(',');
            }
        });

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.types) {
            this.typesList = [];

            changes.types.currentValue.split(',').forEach((type) => {
                this.typesList.push({ key: type, value: type });
            });
        }
    }

    private dialogDelete(dialogElement: any, dialogMessage: string): MatDialogRef<DialogComponent, any> {
        return this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
                title: 'Supprimer',
                message: dialogMessage,
                element: dialogElement,
                textButton: 'Supprimer'
            },
        });
    }

    public add(): void {
        this.resolvers.push({
            KEY: {
                query: '',
                types: []
            }
        });
    }

    public delete(resolverIndex: number): void {
        const dialogRef = this.dialogDelete(this.resolvers[resolverIndex], 'Êtes-vous sûr de vouloir supprimer ce resolver ?');

        dialogRef.afterClosed().pipe(
            tap((res) => {
                if (res) {
                    this.resolvers.splice(resolverIndex, 1);
                }
            })
        ).subscribe();
    }

    public xeditablChange(field: 'key' | 'query' | 'types', textChanged: string, resolverIndex: number): void {
        if (field === 'key') {
            this.resolvers[resolverIndex] = {
                [textChanged]: Object.values(this.resolvers[resolverIndex])[0]
            };
        } else {
            const resolverName = Object.keys(this.resolvers[resolverIndex])[0];

            this.resolvers[resolverIndex][resolverName][field] = textChanged;
        }
    }

    addInChip(event: MatChipInputEvent, index: number): void {

        const resolverName = Object.keys(this.resolvers[index])[0];
        if (!this.matAutocomplete.isOpen) {
            const input = event.input;
            const value = event.value;
            if ((value || '').trim()) {
                this.resolvers[index][resolverName].types.push(value.trim());
            }

            // Reset the input value
            if (input) {
                input.value = '';
            }

            this.typeCtrl.setValue(null);
        }
    }

    remove(type: string, indexRemove: number): void {
        const resolverName = Object.keys(this.resolvers[indexRemove])[0];
        const index = this.resolvers[indexRemove][resolverName].types.indexOf(type);

        if (index >= 0) {
            this.resolvers[indexRemove][resolverName].types.splice(index, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent, index: number): void {
        const resolverName = Object.keys(this.resolvers[index])[0];
        if (typeof this.resolvers[index][resolverName].types === 'string') {
            this.resolvers[index][resolverName].types = this.resolvers[index][resolverName].types.split(',');
        }
        if (!this.resolvers[index][resolverName].types.includes(event.option.viewValue)) {
            this.resolvers[index][resolverName].types.push(event.option.viewValue);
        }
        this.typeInput.nativeElement.value = '';
        this.typeCtrl.setValue(null);
    }

    private filterAutocomplete(value: string): string[] {
        const filterValue = value.toLowerCase();
        const typeTab = [];
        this.typesList.forEach((type) => {
            typeTab.push(type.value);
        });
        return typeTab.filter(type => type.toLowerCase().indexOf(filterValue) === 0);
    }
}
