import { OnInit, Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { tap, startWith, map } from 'rxjs/operators';
import { DialogComponent } from '../../../library/components/dialog/dialog.component';
import { DialogInputComponent } from 'src/app/library/components/dialog-input/dialog-input.component';
import { MatDialogRef, MatDialog, MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
    selector: 'app-transformer-show',
    templateUrl: './transformer-show.component.html',
    styleUrls: ['./transformer-show.component.css']
})
export class TransformerShowComponent implements OnInit, OnChanges {

    @Input() transformers;
    @Input() types;
    public typesList = [];

    valueType: boolean[][] = [];

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];

    typeCtrl = new FormControl();
    filteredTypes: Observable<string[]>;

    @ViewChild('typeInput', { static: false }) typeInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

    constructor(
        public dialog: MatDialog
    ) {
        this.filteredTypes = this.typeCtrl.valueChanges.pipe(
            startWith(null),
            map((type: string | null) => {
                const typeTab = [];
                this.typesList.forEach((types) => {
                    typeTab.push(types.value);
                });
                const typeFiltered = type ? this.filterAutocomplete(type) : typeTab.slice();
                return typeFiltered;
            }));
    }

    ngOnInit(): void {
        this.transformers.forEach((data) => {
            const name = Object.keys(data)[0];
            const valueTypeTab = [];

            data[name].forEach((info) => {
                const nameType = Object.keys(info)[0];

                info[nameType].values ? valueTypeTab.push(true) : valueTypeTab.push(false);
                if (typeof info[nameType].types === 'string') {
                    info[nameType].types = info[nameType].types.split(',');
                }
            });
            this.valueType.push(valueTypeTab);
        });


    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.types) {
            this.typesList = [];

            changes.types.currentValue.split(',').forEach((type) => {
                this.typesList.push({ key: type, value: type });
            });
        }
    }

    addInChip(event: MatChipInputEvent, index: number, indexItem: number): void {

        const namePerimeter = Object.keys(this.transformers[index])[0];
        const typePerimeter = Object.keys(this.transformers[index][namePerimeter][indexItem])[0];

        if (!this.matAutocomplete.isOpen) {
            const input = event.input;
            const value = event.value;
            if ((value || '').trim()) {
                this.transformers[index][namePerimeter][indexItem][typePerimeter].types.push(value.trim());
            }

            if (input) {
                input.value = '';
            }

            this.typeCtrl.setValue(null);
        }
    }

    remove(type: string, indexRemove: number, indexItem: number): void {
        const namePerimeter = Object.keys(this.transformers[indexRemove])[0];
        const typePerimeter = Object.keys(this.transformers[indexRemove][namePerimeter][indexItem])[0];
        const index = this.transformers[indexRemove][namePerimeter][indexItem][typePerimeter].types.indexOf(type);

        if (index >= 0) {
            this.transformers[indexRemove][namePerimeter][indexItem][typePerimeter].types.splice(index, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent, index: number, indexItem: number): void {
        const namePerimeter = Object.keys(this.transformers[index])[0];
        const typePerimeter = Object.keys(this.transformers[index][namePerimeter][indexItem])[0];

        if (typeof this.transformers[index][namePerimeter][indexItem][typePerimeter].types === 'string') {
            this.transformers[index][namePerimeter][indexItem][typePerimeter].types =
                this.transformers[index][namePerimeter][indexItem][typePerimeter].types.split(',');
        }
        if (!this.transformers[index][namePerimeter][indexItem][typePerimeter].types.includes(event.option.viewValue)) {
            this.transformers[index][namePerimeter][indexItem][typePerimeter].types.push(event.option.viewValue);
        }
        this.typeInput.nativeElement.value = '';
        this.typeCtrl.setValue(null);
    }

    private filterAutocomplete(value: string): string[] {
        const filterValue = value.toLowerCase();
        const typeTab = [];

        this.typesList.forEach((type) => {
            typeTab.push(type.value);
        });
        return typeTab.filter(type => type.toLowerCase().indexOf(filterValue) === 0);
    }

    public toggleTranformerItem(event: boolean, transformerIndex: number, transformerTypeIndex: number) {
        const namePerimeter = Object.keys(this.transformers[transformerIndex])[0];
        const typePerimeter = Object.keys(this.transformers[transformerIndex][namePerimeter][transformerTypeIndex])[0];

        this.valueType[transformerIndex][transformerTypeIndex] = event;
        event ? ['types', 'query'].forEach((data) => {
            delete this.transformers[transformerIndex][namePerimeter][transformerTypeIndex][typePerimeter][data];
        }) :
            delete this.transformers[transformerIndex][namePerimeter][transformerTypeIndex][typePerimeter].values;

        !event ? this.transformers[transformerIndex][namePerimeter][transformerTypeIndex][typePerimeter].types = [] :
            this.transformers[transformerIndex][namePerimeter][transformerTypeIndex][typePerimeter].values = '';

    }

    public add(): void {
        const dialogRef = this.dialogAdd(this.transformers, 'Ajouter un perimerter');

        dialogRef.afterClosed()
        .subscribe((res) => {
            if (res) {
                const names = [];
                this.transformers.forEach((role) => {
                    names.push(Object.keys(role)[0]);
                });
                if (!names.includes(res)) {
                    this.transformers.push({
                        [res]: []
                    });
                    this.valueType.push([]);
                }
            }
        });
    }

    public addType(transformerIndex: number, perimeter: string): void {
        this.transformers[transformerIndex][perimeter].push({
            TYPE: {
                // query: '',
                // types: [],
                values: 'VALUE'
            }
        });
        this.valueType[transformerIndex].push(true);
    }

    public delete(transformerIndex: number): void {
        const dialogRef = this.dialogDelete(this.transformers[transformerIndex], 'Êtes-vous sûr de vouloir supprimer ce transformer ?');

        dialogRef.afterClosed().pipe(
            tap((res) => {
                if (res) {
                    this.transformers.splice(transformerIndex, 1);
                    this.valueType.splice(transformerIndex, 1);
                }
            })
        ).subscribe();
    }

    public deleteType(perimeter: string, transformerIndex: number, transformerTypeIndex: number): void {
        const dialogRef = this.dialogDelete(this.transformers[transformerIndex][perimeter], 'Êtes-vous sûr de vouloir supprimer ce type ?');

        dialogRef.afterClosed().pipe(
            tap((res) => {
                if (res) {
                    this.transformers[transformerIndex][perimeter].splice(transformerTypeIndex, 1);
                    this.valueType[transformerIndex].splice(transformerTypeIndex, 1);
                }
            })
        ).subscribe();
    }

    public xeditableChange(field: 'key'|'types', textChanged: string, transformerIndex: number) {
        const namePerimeter = Object.keys(this.transformers[transformerIndex])[0];

        switch (field) {
            case 'key':
                const names = [];
                this.transformers.forEach((role) => {
                    names.push(Object.keys(role)[0]);
                });
                if (!names.includes(textChanged)) {
                    this.transformers[transformerIndex] = {
                        [textChanged]: Object.values(this.transformers[transformerIndex])[0]
                    };
                }
                break;
            case 'types':
                break;
        }
    }

    public xeditableChangeTransformerType(
        field: 'key'|'types', textChanged: string, transformerIndex: number, transformerTypeIndex: number) {
        const namePerimeter = Object.keys(this.transformers[transformerIndex])[0];

        switch (field) {
            case 'key':
                this.transformers[transformerIndex][namePerimeter][transformerTypeIndex] = {
                    [textChanged]: Object.values(this.transformers[transformerIndex][namePerimeter][transformerTypeIndex])[0]
                };
                break;
            case 'types':
                break;
        }
    }

    private dialogDelete(dialogElement: any, dialogMessage: string): MatDialogRef<DialogComponent, any> {
        return this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
                title: 'Supprimer',
                message: dialogMessage,
                element:  dialogElement,
                textButton: 'Supprimer'
            },
        });
    }

    private dialogAdd(dialogElement: any, dialogMessage: string): MatDialogRef<DialogInputComponent, any> {
        return this.dialog.open(DialogInputComponent, {
            width: '500px',
            data: {
                title: 'Ajouter',
                message: dialogMessage,
                element:  dialogElement,
                textButton: 'Ajouter'
            },
        });
    }
}
