import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/library/services/api.service';
import { TableDataSource } from './tableDataSource';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.css']
})
export class TableComponent implements AfterViewInit, OnInit {

    public count = 0;
    public dataSource: TableDataSource;

    @Input() columns;
    @Input() defaultSortColumn;
    @Input() defaultSortDirection;
    @Input() displayedColumns;
    @Input() service: ApiService;
    @Input() extra: any;

    @ViewChild('input', { static: true }) input: ElementRef;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor() { }

    ngOnInit(): void {
        // Set dataSource (expect Observable)
        this.dataSource = new TableDataSource(this.service);
        this.dataSource.loadData(this.defaultSortColumn, this.defaultSortDirection, 0, 5, '', this.extra);
    }

    ngAfterViewInit() {
        // Listener Input Filter change
        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap(() => {
                    this.paginator.pageIndex = 0;
                    this.loadDataPage();
                })
            )
            .subscribe();

        this.dataSource.countData$.subscribe(c => this.count = c);

        // Listener Sort change
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.loadDataPage())
            )
            .subscribe();
    }

    loadDataPage() {
        this.dataSource.loadData(
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.input.nativeElement.value);
    }
}
