import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { tap } from 'rxjs/operators';
import { DialogComponent } from 'src/app/library/components/dialog/dialog.component';
import { DialogInputComponent } from 'src/app/library/components/dialog-input/dialog-input.component';
import { MatDialogRef, MatDialog } from '@angular/material';

@Component({
    selector: 'app-option-show',
    templateUrl: './option-show.component.html',
    styleUrls: ['./option-show.component.css']
})
export class OptionShowComponent implements OnChanges {

    @Input() options;
    @Input() types;
    public typesList = [];

    constructor(
        public dialog: MatDialog
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.types) {
            this.typesList = [];

            changes.types.currentValue.split(',').forEach((type) => {
                this.typesList.push({ key: type, value: type });
            });
        }
    }

    public add(): void {
        const dialogRef = this.dialogAdd(this.options, 'Ajouter une option');

        dialogRef.afterClosed()
        .subscribe((res) => {
            if (res) {
                const names = [];
                this.options.forEach((role) => {
                    names.push(Object.keys(role)[0]);
                });
                if (!names.includes(res)) {
                    this.options.push({
                        [res]: {
                            label: '',
                            optionPermissionTable: {
                                idColumn: '',
                                optionColumn: '',
                                table: ''
                            },
                            optionTable: {
                                idColumn: '',
                                nameColumn: '',
                                permissionAction: '',
                                table: ''
                            },
                            values: ''
                        }
                    });
                }
            }
        });
        // this.options.push({
        //     TYPE: {
        //         label: '',
        //         optionPermissionTable: {
        //             idColumn: '',
        //             optionColumn: '',
        //             table: ''
        //         },
        //         optionTable: {
        //             idColumn: '',
        //             nameColumn: '',
        //             permissionAction: '',
        //             table: ''
        //         },
        //         values: ''
        //         // query: 'QUERY',
        //         // values: 'VALUES'
        //     }
        // });
    }

    public delete(roleOptionIndex: number): void {
        const dialogRef = this.dialogDelete(this.options[roleOptionIndex], 'Êtes-vous sûr de vouloir supprimer cette option ?');

        dialogRef.afterClosed().pipe(
            tap((res) => {
                if (res) {
                    this.options.splice(roleOptionIndex, 1);
                }
            })
        ).subscribe();
    }

    public xeditablChange(field: 'key'|'values'|'label', textChanged: string, roleOptionIndex: number): void {
        if (field === 'key') {
            this.options[roleOptionIndex] = {
                [textChanged]: Object.values(this.options[roleOptionIndex])[0]
            };
        } else {
            const type = Object.keys(this.options[roleOptionIndex])[0];

            this.options[roleOptionIndex][type][field] = textChanged;
        }
    }

    public xeditablOptionChange(option: 'optionPermissionTable'|'optionTable', textChanged: string, roleOptionIndex: number,
                                field: string): void {
        const type = Object.keys(this.options[roleOptionIndex])[0];

        this.options[roleOptionIndex][type][option][field] = textChanged;
    }

    private dialogDelete(dialogElement: any, dialogMessage: string): MatDialogRef<DialogComponent, any> {
        return this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
                title: 'Supprimer',
                message: dialogMessage,
                element:  dialogElement,
                textButton: 'Supprimer'
            },
        });
    }

    private dialogAdd(dialogElement: any, dialogMessage: string): MatDialogRef<DialogInputComponent, any> {
        return this.dialog.open(DialogInputComponent, {
            width: '500px',
            data: {
                title: 'Ajouter',
                message: dialogMessage,
                element:  dialogElement,
                textButton: 'Ajouter'
            },
        });
    }
}
