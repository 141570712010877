import { OnInit, Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { tap } from 'rxjs/operators';
import { DialogComponent } from 'src/app/library/components/dialog/dialog.component';

@Component({
    selector: 'app-condition-query',
    templateUrl: './condition-query.component.html',
    styleUrls: ['./condition-query.component.css']
})
export class ConditionQueryComponent {

    @Input() query;
    public ConditionTypeList = [
        { key: 'in', value: 'IN' },
        { key: 'isEmpty', value: 'IS EMPTY' },
        { key: 'isNotEmpty', value: 'IS NOT EMPTY' },
        { key: 'isNotNull', value: 'IS NOT NULL' },
        { key: 'isNull', value: 'IS NULL' },
    ];

    constructor(private dialog: MatDialog) {}

    public add(): void {
        this.query.conditions.push({
            parameter: 'parameter',
            type: 'in',
            value: 'value'
        });
    }

    public xeditableChange(field: 'parameter'|'type'|'value', textChanged: string, conditionIndex: number): void {
            this.query.conditions[conditionIndex][field] = textChanged;
    }

    public delete(conditionIndex): void {
        const dialogRef = this.dialogDelete(this.query.conditions[conditionIndex], 'Êtes-vous sûr de supprimer cette condition ?');

        dialogRef.afterClosed().pipe(
            tap((res) => {
                if (res) {
                    this.query.conditions.splice(conditionIndex, 1);
                }
            })
        ).subscribe();
    }

    private dialogDelete(dialogElement: any, dialogMessage: string): MatDialogRef<DialogComponent, any> {
        return this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
                title: 'Supprimer',
                message: dialogMessage,
                element:  dialogElement,
                textButton: 'Supprimer'
            },
        });
    }
}
