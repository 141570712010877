import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SharedModule } from './shared/shared.module';
// import { AuthenticationModule } from './authentication/authentication.module';
// import { CoreModule } from './core/core.module';
// import { ProjectModule } from './project/project.module';

import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProjectRouteShowComponent } from './pages/project/show-route/project-route-show.component';
import { ProjectShowComponent } from './pages/project/show/project-show.component';
import { ProjectTableComponent } from './pages/project/table/project-table.component';
import { PageModule } from './pages/pages.module';

const routes: Routes = [
  { path: '', component: ProjectTableComponent },
  { path: 'project/table', component: ProjectTableComponent },
  { path: 'project/show/:code/:id', component: ProjectShowComponent },
  { path: 'project/show/:id', component: ProjectShowComponent },
  { path: 'project/:code/route/show/:id', component: ProjectRouteShowComponent }
];


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    SharedModule,
    PageModule,
    // AuthenticationModule,
    // CoreModule,
    // ProjectModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
