import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApiService } from '../library/services/api.service';
import { ConnectionService } from '../shared/services/connection.service';
import { map } from 'rxjs/operators';
import { DynamoToJsonProjectRouteTransform } from '../shared/transform/project-route/dynamo-to-json-project-route.transform';
import { JsonToDynamoProjectRouteTransform } from '../shared/transform/project-route/json-to-dynamo-project-route.transform';
import { DynamoProjectRoute, DynamoProjectRoutes } from '../shared/transform/models/dynamo-project-route.model';

@Injectable()
export class ProjectRouteApiService extends ApiService {

    constructor(
        protected http: HttpClient,
        protected connectionService: ConnectionService,
        protected dynamoToJsonProjectRouteTransform: DynamoToJsonProjectRouteTransform,
        protected jsonToDynamoProjectRouteTransform: JsonToDynamoProjectRouteTransform) {
        super(http, connectionService);
    }

    public getTableData(
        sortColumn, sortOrder = 'asc', pageNumber = 0, pageSize = 5, filter = '', extra = {}): Observable<any> {
        const options = this.getOptions({ sortColumn, sortOrder, offset: pageNumber * pageSize, limit: pageSize, filter });

        return this.http.get(`${this.apiUrl}/projects/${extra['projectCode']}/routes`, options).pipe(
            map((dynamoProjectRoutes: DynamoProjectRoutes) => {
                const routes = {
                    count: dynamoProjectRoutes.Count,
                    data: []
                };

                dynamoProjectRoutes.Items.forEach((dyanmoProjectRoute) => {
                    const route = {
                        key: dyanmoProjectRoute.key.S,
                        projectCode: extra['projectCode']
                    };
                    routes.data.push(route);
                });

                return routes;
            })
        );
    }

    public getProjectRoutes(projectCode: string) {
        const options = this.getOptions();

        return this.http.get(`${this.apiUrl}/projects/${projectCode}/routes`, options).pipe(
            map((dynamoProjectRoutes: DynamoProjectRoutes) => {
                const routes = [];

                if (dynamoProjectRoutes.Items) {
                    dynamoProjectRoutes.Items.forEach((dyanmoProjectRoute) => {
                        routes.push(dyanmoProjectRoute.key.S);
                    });
                }
                return routes;
            })
        );
    }

    public getProjectRouteData(projectCode: string, routeId: string) {
        return this.http.get(
            `${this.apiUrl}/projects/${projectCode}/routes/routeid`, this.getOptions({ routeId: routeId.replace(/&/g, '/') })).pipe(
            map((dynamoProjectRoute: /*DynamoProjectRoute*/any) => {
                return this.dynamoToJsonProjectRouteTransform.execute(dynamoProjectRoute);
            })
        );
    }

    public putProjectRouteData(projectCode: string, routeId: string, projectRoute) {

        const dynamoProjectRoute = this.jsonToDynamoProjectRouteTransform.execute(projectRoute);
        dynamoProjectRoute.TableName = projectCode;

        return this.http.put(
            `${this.apiUrl}/projects/${projectCode}/routes/routeid`,
            dynamoProjectRoute, this.getOptions({ routeId: routeId.replace(/&/g, '/') })
        );
    }

    public postRoute(route: { key: string; }, projectCode: string): Observable<any> {
        const body = {
            TableName: projectCode,
            Item: {
                key: { S: route.key }
            }
        };

        return this.http.post(`${this.apiUrl}/projects/${projectCode}/routes`, body, this.getOptions());
    }
}
